import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import "./StepperProgessbar.css"; // Import CSS for styling
import { FaCheck } from "react-icons/fa6";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Default for small screens
  maxWidth: "600px", // Medium screens
  "@media (min-width: 600px)": {
    width: "75%", // Larger screens
    maxWidth: "800px",
  },
  "@media (min-width: 992px)": {
    width: "40%", // Extra large screens
    maxWidth: "992px",
  },
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  gap: "11px",
  outline: "none",
  p: 6,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
};

const messageStyle = {
  marginTop: "16px",
  fontWeight: 500,
  color: "#555",
  transition: "opacity 1s ease-in-out",
};

const LoadingModal = ({text, open, progressbar, steps }) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const nextStep = steps.findIndex(
      (_, index) => !progressbar[`step${index + 1}`]
    );
    if (nextStep !== -1) {
      setCurrentStep(nextStep);
    }
  }, [progressbar, steps]);

  const [messageIndex, setMessageIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const messages = [
    <p className="paymentSuccessful">
      <FaCheck
        style={{
          background: "#bacd5399",
          padding: "5px",
          fontSize: "26px",
          borderRadius: "19px",
        }}
      />
      {/* Payment Successful */}
      {text}
    </p>,
    "Creating your book...",
    "Don’t close your window...",
    "Please wait...",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        setFade(true);
      }, 500); // Change message halfway through the fade-out transition
    }, 3000); // Change message every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Modal
      open={open}
      onClose={null} // Disable the default onClose behavior
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      aria-labelledby="loading-modal-title"
      aria-describedby="loading-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box sx={style}>
        <CircularProgress sx={{ color: "#BACD63", zIndex: 1300 }} />

        <Typography
          id="loading-modal-description"
          sx={{
            ...messageStyle,
            opacity: fade ? 1 : 0,
          }}
        >
          {messages[messageIndex]}
        </Typography>
      </Box>
    </Modal>
  );
};

export default LoadingModal;
