// import React from "react";
// import styles from "./OrderConfirmPage.module.css";
// import { useNavigate } from "react-router-dom";

// const OrderConfirmPage = () => {
// const navigate = useNavigate();

//   return (
//     <div className={styles.container}>
//       <div className={styles.card}>
//         <h1 className={styles.title}>Thank You for Your Order!</h1>
//         <p className={styles.message}>
//           Your order has been successfully placed. We appreciate your business and hope you enjoy your purchase!
//         </p>
//         <div className={styles.orderSummary}>
//           <h2>Order Summary</h2>
//           <ul>
//             <li>
//               <span>Item:</span> Stylish Lamp
//             </li>
//             <li>
//               <span>Order Number:</span> #123456
//             </li>
//             <li>
//               <span>Total:</span> $79.99
//             </li>
//           </ul>
//         </div>
//         <button className={styles.button} onClick={() => navigate('/our-books')}>Continue Shopping</button>
//       </div>
//     </div>
//   );
// };

// export default OrderConfirmPage;


import React, { useEffect } from "react";
import styles from "./OrderConfirmPage.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";

const OrderConfirmPage = () => {
  const navigate = useNavigate();
  const {cart:{cart, totalPrice, totalCount,finalTotalPrice, tax,discount, isPromoapplied, voucherPromoCode, totalBasePrice}} = useSelector((cart)=>cart)
  const location = useLocation();
  const { orderDetails } = location.state || {}; // Safely access state

  console.log("orderDetails",orderDetails)

  useEffect(()=>{
    if(!orderDetails || orderDetails === undefined){
      navigate('/our-books')
    }
  },[orderDetails,navigate])

  return (
    <>
    {orderDetails || orderDetails !== undefined
    ?
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>Thank You!</h1>
        <p className={styles.message}>
          Thank you for your order! We can't wait for you to dive into your new adventures. Happy reading!
        </p>
        <div className={styles.orderSummary}>
          <h2>Your Order Details</h2>
          <ul>
            <li>
              <span>Order Number:</span>{orderDetails ? orderDetails.PK : 'Null'}
            </li>
            <li>
              <span>Total Price:</span> {orderDetails ? `£ ${orderDetails.paymentDetails.totalAmount}` : "0"}
            </li>
            {/* <li>
              <span>Total:</span> $24.99
            </li> */}
          </ul>
        </div>
        {/* <button className={styles.button} onClick={() => navigate("/our-books")}>
          Discover More Books
        </button> */}
        <div className={styles.buttondiv}>
            <div className={styles.CreateYourOwnBook}>
              {/* <CreateBookSvg /> */}
              <CustomButton incoBg={true} icon={<IoIosArrowForward style={{width:"30px", height:"30px"}} />} outerDivBg={"#BB82F9"} innerDivBg={"#AA76E2"} text={"Discover More Books"} fontSize={"16px"} onClick={()=> navigate('/our-books')} width={"240px"} height={"60px"} topGradient={"rgb(189 158 220)"} bottomGradient={"rgb(156 102 212)"} />
            </div>
        </div>
      </div>
    </div>:
    <div></div>}
    </>
  );
};

export default OrderConfirmPage;
