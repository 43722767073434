import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import CreateYourCharacterstyles from "./CreateYourCharacter.module.css";
import "../../fonts.css";
import Footer from "../../Components/Footer/Footer";
import { addToCart } from "../../features/cart/cartSlice"
import { ReactComponent as NextBtnCharacter } from "../../Assets/SVG/NextBtnCharacter.svg";
import characters from '../../Assets/Backgrounds/CreateYourCharacter/characters.webp'
import dress from "../../Assets/Images/characters/Girl/dress.webp";
import lightskin from "../../Assets/Images/characters/Girl/lightskin.webp";
import darkskin from "../../Assets/Images/characters/Girl/darkskin.webp";
import middleskin from "../../Assets/Images/characters/Girl/middleskin.webp";

//short
import girlshortbrown from "../../Assets/Images/characters/Girl/short_hair_brown.webp";
import girlshortblack from "../../Assets/Images/characters/Girl/short_hair_black.webp";
import girlshortblonde from "../../Assets/Images/characters/Girl/short_hair_blande.webp";
import girlshortginger from "../../Assets/Images/characters/Girl/short_hair_ginger.webp";

//long
import girllongbrown from "../../Assets/Images/characters/Girl/long_hair_brown.webp";
import girllongblack from "../../Assets/Images/characters/Girl/long_hair_black.webp";
import girllongblonde from "../../Assets/Images/characters/Girl/long_hair_blande.webp";
import girllongginger from "../../Assets/Images/characters/Girl/Long_hair_ginger.webp";

//afro
import girlafrobrown from "../../Assets/Images/characters/Girl/afro_hair_brown.webp";
import girlafroblack from "../../Assets/Images/characters/Girl/afro_hair_black.webp";
import girlafroblonde from "../../Assets/Images/characters/Girl/afro_hair_blande.webp";
import girlafroginger from "../../Assets/Images/characters/Girl/afro_hair_ginger.webp";

import browneye from "../../Assets/Images/characters/Girl/browneye.webp";
import blueeye from "../../Assets/Images/characters/Girl/blueeye.webp";
import greeneye from "../../Assets/Images/characters/Girl/greeneye.webp";
import hazeleye from "../../Assets/Images/characters/Girl/hazeleye.webp";

import boydress from "../../Assets/Images/characters/Boy/boydress.webp";
import boylightskin from "../../Assets/Images/characters/Boy/boylightskin.webp";
import boydarkskin from "../../Assets/Images/characters/Boy/boydarkskin.webp";
import boymiddleskin from "../../Assets/Images/characters/Boy/boymiddleskin.webp";

//boy short hair
import boyshortbrownhair from "../../Assets/Images/characters/Boy/hair_short_brown.webp";
import boyshortgingerhair from "../../Assets/Images/characters/Boy/hair_short_ginger.webp";
import boyshortblondehair from "../../Assets/Images/characters/Boy/hair_short_blonde.webp";
import boyshortblackhair from "../../Assets/Images/characters/Boy/hair_short_black.webp";
//boy long hair
import boylongbrownhair from "../../Assets/Images/characters/Boy/hair_long_brown.webp";
import boylongblackhair from "../../Assets/Images/characters/Boy/hair_long_black.webp";
import boylongblondehair from "../../Assets/Images/characters/Boy/hair_long_blonde.webp";
import boylonggingerhair from "../../Assets/Images/characters/Boy/hair_long_ginger.webp";
//boy afro hair
import boyafrobrownhair from "../../Assets/Images/characters/Boy/hair_afro_brown.webp";
import boyafroblackhair from "../../Assets/Images/characters/Boy/hair_afro_black.webp";
import boyafroblondehair from "../../Assets/Images/characters/Boy/hair_afro_blonde.webp";
import boyafrogingerhair from "../../Assets/Images/characters/Boy/hair_afro_ginger.webp";

import boyblueeye from "../../Assets/Images/characters/Boy/boyblueeye.webp";
import boybrowneye from "../../Assets/Images/characters/Boy/boybrowneye.webp";
import boygreeneye from "../../Assets/Images/characters/Boy/boygreeneye.webp";
import boyhazeleye from "../../Assets/Images/characters/Boy/boyhazeleyeeye.webp";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { setchildname, seteyeColor, setgender, sethaircolor, sethairstyle, setskincolor, setTeacher1, setTeacher2, setTeacher3, setTeacher4, settown } from "../../features/characterSlice";


import { setcolor, setIcon, setmessage, setsize, settoaststate, setuniqueId, setvariant } from '../../features/toastSlice';
import ConfirmModal from "./ConfirmModal";

const girlskinOptions = [
  { id: 1, src: lightskin, alt: "lightskin" },
  { id: 2, src: middleskin, alt: "middleskin" },
  { id: 3, src: darkskin, alt: "darkskin" },
];


const girlshortOptions = [
  { id: 1, src: girlshortblack, alt: "Blackhair" },
  { id: 2, src: girlshortbrown, alt: "Brownhair" },
  { id: 3, src: girlshortblonde, alt: "Blondehair" },
  { id: 4, src: girlshortginger, alt: "Gingerhair" }
];


const girllongOptions = [
  { id: 1, src: girllongblack, alt: "Blackhair" },
  { id: 2, src: girllongbrown, alt: "Brownhair" },
  { id: 3, src: girllongblonde, alt: "Blondehair" },
  { id: 4, src: girllongginger, alt: "Gingerhair" }
];

const girlafroOptions = [
  { id: 1, src: girlafroblack, alt: "Blackhair" },
  { id: 2, src: girlafrobrown, alt: "Brownhair" },
  { id: 3, src: girlafroblonde, alt: "Blondehair" },
  { id: 4, src: girlafroginger, alt: "Gingerhair" }
];


const girleyeOptions = [
  { id: 1, src: browneye, alt: "Browneye" },
  { id: 2, src: blueeye, alt: "Blueeye" },
  { id: 3, src: greeneye, alt: "Greeneye" },
  { id: 4, src: hazeleye, alt: "Hazeleye" }
];

///
const boyskinOptions = [
  { id: 1, src: boylightskin, alt: "lightskin" },
  { id: 2, src: boymiddleskin, alt: "middleskin" },
  { id: 3, src: boydarkskin, alt: "darkskin" },
];

// const boyhairOptions = [
//   { id: 1, src: boyshortbrownhair, alt: "Brownhair" },
//   { id: 2, src: boyshortgingerhair, alt: "Brownhair" },
//   { id: 3, src: boyshortblondehair, alt: "Blondehair" },
//   { id: 3, src: boyshortblackhair, alt: "Blackhair" },
// ];
// Define your initial hair options
const boyshortHairOptions = [
  { id: 1, src: boyshortbrownhair, alt: "Brownhair" },
  { id: 2, src: boyshortgingerhair, alt: "Gingerhair" },
  { id: 3, src: boyshortblondehair, alt: "Blondehair" },
  { id: 4, src: boyshortblackhair, alt: "Blackhair" },
];


const boylongHairOptions = [
  { id: 1, src: boylongbrownhair, alt: "Brownhair" },
  { id: 2, src: boylonggingerhair, alt: "Gingerhair" },
  { id: 3, src: boylongblondehair, alt: "Blondehair" },
  { id: 4, src: boylongblackhair, alt: "Blackhair" },
];

const boyafroHairOptions = [
  { id: 1, src: boyafrobrownhair, alt: "Brownhair" },
  { id: 2, src: boyafrogingerhair, alt: "Gingerhair" },
  { id: 3, src: boyafroblondehair, alt: "Blondehair" },
  { id: 4, src: boyafroblackhair, alt: "Blackhair" },
];
// const boyhairOptions = {
//   Short: { id: 1, src: boyShortHair, alt: "ShortHair" },
//   Long: { id: 2, src: boyLongHair, alt: "LongHair" },
//   Afro: { id: 3, src: boyAfroHair, alt: "AfroHair" },
// };

const boyeyeOptions = [
  { id: 1, src: boybrowneye, alt: "Browneye" },
  { id: 2, src: boyblueeye, alt: "Blueeye" },
  { id: 3, src: boygreeneye, alt: "Greeneye" },
  { id: 4, src: boyhazeleye, alt: "Hazeleye" }
];

const CreateYourCharacter = () => {
  const navigate = useNavigate();
  const containerRefscrollcharacter = useRef(null);

  const {cart:{cart, totalPrice, totalCount, tax,discount, isPromoapplied, voucherPromoCode, totalBasePrice}} = useSelector((cart)=>cart)

  const location = useLocation();
  const { productToRoute, uniqueIdToRoute } = location.state || {};

  // console.log("productToRoute===>", productToRoute)
  // console.log("uniqueIdToRoute===>", uniqueIdToRoute)

  const [activeOption, setActiveOption] = useState('skin'); // Default to 'skin'
  const [activehairOption, setActiveHairOption] = useState('short');
  const [currectWidth, setCurrectWidth] = useState(window.innerWidth <= 768);

  const hairOptionsfor786 = ['short', 'long', 'afro'];
  const [currentHairIndex, setCurrentHairIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentHairIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? hairOptionsfor786.length - 1 : prevIndex - 1;
      handleHairTypeChange(hairOptionsfor786[newIndex]);
      return newIndex;
    });
  };

  const handleNext = () => {
    setCurrentHairIndex((prevIndex) => {
      const newIndex = prevIndex === hairOptionsfor786.length - 1 ? 0 : prevIndex + 1;
      handleHairTypeChange(hairOptionsfor786[newIndex]);
      return newIndex;
    });
  };


  const currentHairtitle = hairOptionsfor786[currentHairIndex];

  useEffect(() => {
    const handleResize = () => {
      // if (window.innerWidth < 768) {

      // }
      // console.log("window.innerWidth", window.innerWidth)

      // console.log("currectWidth", currectWidth)

      setCurrectWidth(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [data, setData] = useState({
    childName: "",
    townName: ""
  })

  const [teacherName, setTeacherName] = useState({
    teacher1 : "",
    teacher2 : "",
    teacher3 : "",
    teacher4 : ""
  })

  const [error, setError] = useState({
    childName: "",
    townName: "",
    teacher1 : "",
    teacher2 : "",
    teacher3 : "",
    teacher4 : ""
  })

  const dispatch = useDispatch()
  // const { state } = useLocation()
  // const { product } = state || {}
  // console.log("Product  : ", product)

  const [charactertoggle, setCharactertoggle] = useState("Boy");

  const [girlhairnewOptions, setGirlHairOptions] = useState(girlshortOptions);

  const [selectedSkin, setSelectedSkin] = useState(girlskinOptions[0]); // Default to first skin option
  const [selectedHair, setSelectedHair] = useState(girlhairnewOptions[0]); // Default to first hair option
  const [selectedEyes, setSelectedEyes] = useState(girleyeOptions[0]); // Default to first eye option

  const [hairOptions, setHairOptions] = useState(boyshortHairOptions);

  const [selectedHairType, setSelectedHairType] = useState('short');

  const [boyselectedSkin, setBoyselectedSkin] = useState(boyskinOptions[0]); // Default to first skin option
  const [boyselectedHair, setBoyselectedHair] = useState(hairOptions[0]); // Default to first hair option
  const [boyselectedEyes, setBoyselectedEyes] = useState(boyeyeOptions[0]); // Default to first eye option

  const handleCharactertoggle = (val) => {
    setCharactertoggle(val);
    // setSelectedHairType('short');
    setBoyselectedHair(boyshortHairOptions[0])
    setSelectedHair(girlshortOptions[0])
  };

  const handleHairTypeChange = (type) => {
    setSelectedHairType(type);
    switch (type) {
      case 'short':
        setHairOptions(boyshortHairOptions);
        setBoyselectedHair(boyshortHairOptions[0])
        // setBoyselectedHair()

        setGirlHairOptions(girlshortOptions)
        setSelectedHair(girlshortOptions[0])
        break;
      case 'long':
        setHairOptions(boylongHairOptions);
        setBoyselectedHair(boylongHairOptions[0])


        setGirlHairOptions(girllongOptions)
        setSelectedHair(girllongOptions[0])
        break;
      case 'afro':
        setHairOptions(boyafroHairOptions);
        setBoyselectedHair(boyafroHairOptions[0])


        setGirlHairOptions(girlafroOptions)
        setSelectedHair(girlafroOptions[0])
        break;
      default:
        setHairOptions(boyshortHairOptions);
    }
  };

  const [open, setOpen] = useState(false);

  
  const handleClose = () => setOpen(false);

  // const handleOpen = async () => {
  //   if (!data.childName) {
  //     setError((prev) => ({
  //       ...prev,
  //       childName: "Child name can never be empty."
  //     }))
  //     // return
  //   } else {
  //     setError((prev) => ({
  //       ...prev,
  //       childName: ""
  //     }))
  //   }
  //   if (!data.townName) {
  //     setError((prev) => ({
  //       ...prev,
  //       townName: "Town name can never be empty."
  //     }))

  //     // return
  //   } else {
  //     setError((prev) => ({
  //       ...prev,
  //       townName: ""
  //     }))
  //   }

  //   if(data.childName.length >15){
  //     dispatch(settoaststate(true));
  //     dispatch(setsize('sm'));
  //     dispatch(setvariant('warning'));
  //     dispatch(setcolor('warning'));
  //     dispatch(setmessage('Name cannot exceed 15 characters. Please shorten it.'));
  //     dispatch(setIcon('warning'));
  //     dispatch(setuniqueId(`data.childName`))
  //     return
  //   }
  //   if(data.townName.length >15){
  //     dispatch(settoaststate(true));
  //     dispatch(setsize('sm'));
  //     dispatch(setvariant('warning'));
  //     dispatch(setcolor('warning'));
  //     dispatch(setmessage('Town name cannot exceed 15 characters. Please shorten it.'));
  //     dispatch(setIcon('warning'));
  //     dispatch(setuniqueId(`data.childName`))
  //     return
  //   }
  //   if(productToRoute.bookId === "Teacher"){

  //   if (!teacherName.teacher1) {
  //     setError((prev) => ({
  //       ...prev,
  //       teacher1: "Teacher Name can never be empty."
  //     }))

  //     // return
  //   } else {
  //     setError((prev) => ({
  //       ...prev,
  //       teacher1: ""
  //     }))
  //   }

  //   if (!teacherName.teacher2) {
  //     setError((prev) => ({
  //       ...prev,
  //       teacher2: "Teacher Name can never be empty."
  //     }))

  //     // return
  //   } else {
  //     setError((prev) => ({
  //       ...prev,
  //       teacher2: ""
  //     }))
  //   }

  //   if (!teacherName.teacher3) {
  //     setError((prev) => ({
  //       ...prev,
  //       teacher3: "Teacher Name can never be empty."
  //     }))

  //     // return
  //   } else {
  //     setError((prev) => ({
  //       ...prev,
  //       teacher3: ""
  //     }))
  //   }

  //   if (!teacherName.teacher4) {
  //     setError((prev) => ({
  //       ...prev,
  //       teacher4: "Teacher Name can never be empty."
  //     }))

  //     return
  //   } else {
  //     setError((prev) => ({
  //       ...prev,
  //       teacher4: ""
  //     }))
  //   }    
  // }

  // if (productToRoute.bookId === "Teacher") {
  //   for (let i = 1; i <= 4; i++) {
  //     const teacherField = `teacher${i}`;

  //     // if (!teacherName.teacherField) {
  //     //   setError((prev) => ({
  //     //     ...prev,
  //     //     [teacherField]: `Teacher ${i} name cannot be empty.`
  //     //   }));
  //     //   // return;
  //     // } else 
  //     if (teacherName[teacherField].length > 15) {
  //       dispatch(settoaststate(true));
  //       dispatch(setsize('sm'));
  //       dispatch(setvariant('warning'));
  //       dispatch(setcolor('warning'));
  //       dispatch(setmessage(`Teacher name cannot exceed 15 characters. Please shorten it.`));
  //       dispatch(setIcon('warning'));
  //       dispatch(setuniqueId(`teacherName.${teacherField}`));

  //       setError((prev) => ({
  //         ...prev,
  //         [teacherField]: `Please shorten it.`
  //       }));

  //       return;
  //     }
  //   }
  // }

  //   setOpen(true)
  // };

  const handleOpen = async () => {
    let hasError = false; // Track if there are any errors to prevent popup from opening
  
    // Check required fields for childName and townName
    if (!data.childName) {
      setError((prev) => ({ ...prev, childName: "Child name can never be empty." }));
      hasError = true;
    } else {
      setError((prev) => ({ ...prev, childName: "" }));
    }
  
    if (!data.townName) {
      setError((prev) => ({ ...prev, townName: "Town name can never be empty." }));
      hasError = true;
    } else {
      setError((prev) => ({ ...prev, townName: "" }));
    }
  
    // Check required fields for each teacher if bookId is "Teacher"
    if (productToRoute.bookId === "Teacher") {
      for (let i = 1; i <= 4; i++) {
        const teacherField = `teacher${i}`;
        if (!teacherName[teacherField]) {
          setError((prev) => ({ ...prev, [teacherField]: `Teacher name cannot be empty.` }));
          hasError = true;
        } else {
          setError((prev) => ({ ...prev, [teacherField]: "" }));
        }
      }
    }
  
    // If any required field is missing, stop execution
    if (hasError) return;
  
    // Check length constraints for childName
    if (data.childName.length > 15) {
      dispatch(settoaststate(true));
      dispatch(setsize('sm'));
      dispatch(setvariant('warning'));
      dispatch(setcolor('warning'));
      dispatch(setmessage('Child name cannot exceed 15 characters. Please shorten it.'));
      dispatch(setIcon('warning'));
      dispatch(setuniqueId('data.childName'));
  
      setError((prev) => ({
        ...prev,
        childName: "Please shorten it."
      }));
      return;
    }
  
    // Check length constraints for townName
    if (data.townName.length > 15) {
      dispatch(settoaststate(true));
      dispatch(setsize('sm'));
      dispatch(setvariant('warning'));
      dispatch(setcolor('warning'));
      dispatch(setmessage('Town name cannot exceed 15 characters. Please shorten it.'));
      dispatch(setIcon('warning'));
      dispatch(setuniqueId('data.townName'));
  
      setError((prev) => ({
        ...prev,
        townName: "Please shorten it."
      }));
      return;
    }
  
    // Check length constraints for each teacher if bookId is "Teacher"
    if (productToRoute.bookId === "Teacher") {
      for (let i = 1; i <= 4; i++) {
        const teacherField = `teacher${i}`;
        if (teacherName[teacherField] && teacherName[teacherField].length > 15) {
          dispatch(settoaststate(true));
          dispatch(setsize('sm'));
          dispatch(setvariant('warning'));
          dispatch(setcolor('warning'));
          dispatch(setmessage(`Teacher name cannot exceed 15 characters. Please shorten it.`));
          dispatch(setIcon('warning'));
          dispatch(setuniqueId(`teacherName.${teacherField}`));
          
          setError((prev) => ({
            ...prev,
            [teacherField]: "Please shorten it."
          }));
          
          return;
        }
      }
    }
  
    // Open popup only if all validations pass
    setOpen(true);
  };
  
  

  const handleConfirm = () => {
    // console.log('Information confirmed!');
    handleClose();
    handleAddToCart();
  };

  const handleboyskin = (option) => {
    setBoyselectedSkin(option)
  }

  const handleChangeInput = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const handleChangeInputTeacher = (e) => {
    setTeacherName((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const generateRandomID = (childName) => {
    return `bookID-${childName}-${Math.random().toString(36).substr(2, 9)}`;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  const eyeColorMapping = {
    Browneye: 'brown',
    Blueeye: 'blue',
    Greeneye: 'green',
    Hazeleye: 'hazel'
  };

  const skinColorMapping = {
    lightskin: 'light',
    middleskin: 'tan',
    darkskin: 'dark'
  };

  const hairStyleMapping = {
    short: 'short',
    long: 'long',
    afro: 'afro'
  };

  const hairColorMapping = {
    Blackhair: 'black',
    Brownhair: 'brown',
    Blondehair: 'blonde',
    Gingerhair: 'ginger'
  };

  const [bulkPurchasepopUp, setBulkPurchasepopUp] = useState(false); 

  const handleAddToCart = () => {
    let isdone = false

    const bookTempID = generateRandomID(data.childName)
    const formattedChildName = capitalizeFirstLetter(data.childName);
    const formattedTown = capitalizeFirstLetter(data.townName);
    let newBookDetail


    if (charactertoggle === 'Boy') {

      dispatch(setgender(charactertoggle))
      dispatch(seteyeColor(boyselectedEyes.alt))
      dispatch(setskincolor(boyselectedSkin.alt))
      dispatch(sethairstyle(selectedHairType))
      dispatch(sethaircolor(boyselectedHair.alt))
      dispatch(settown(data.townName))
      dispatch(setchildname(data.childName))

      dispatch(setTeacher1(teacherName.teacher1))
      dispatch(setTeacher2(teacherName.teacher2))
      dispatch(setTeacher3(teacherName.teacher3))
      dispatch(setTeacher4(teacherName.teacher4))

      



if(productToRoute.bookId === 'Teacher'){
  newBookDetail = {
    "gender": charactertoggle,
    "book": `bookID-${capitalizeFirstLetter(productToRoute.bookId)}`,
    "eye-color": eyeColorMapping[boyselectedEyes.alt] || boyselectedEyes.alt,
    "skin-color": skinColorMapping[boyselectedSkin.alt] || boyselectedSkin.alt,
    "hair-style": hairStyleMapping[selectedHairType] || selectedHairType,
    "hair-color": hairColorMapping[boyselectedHair.alt] || boyselectedHair.alt,
    "town": formattedTown,
    "child-name": formattedChildName,
    "teacher1" : teacherName.teacher1,
    "teacher2": teacherName.teacher2,
    "teacher3": teacherName.teacher3,
    "teacher4": teacherName.teacher4,
    "quantity": 1,
    "bookTempID": bookTempID,
  };
}else{
      newBookDetail = {
        "gender": charactertoggle,
        "book": `bookID-${capitalizeFirstLetter(productToRoute.bookId)}`,
        "eye-color": eyeColorMapping[boyselectedEyes.alt] || boyselectedEyes.alt,
        "skin-color": skinColorMapping[boyselectedSkin.alt] || boyselectedSkin.alt,
        "hair-style": hairStyleMapping[selectedHairType] || selectedHairType,
        "hair-color": hairColorMapping[boyselectedHair.alt] || boyselectedHair.alt,
        "town": formattedTown,
        "child-name": formattedChildName,
        "quantity": 1,
        "bookTempID": bookTempID,
      };
    }


      isdone = true
    }
    if (charactertoggle === 'Girl') {
      dispatch(setgender(charactertoggle))
      dispatch(seteyeColor(selectedEyes.alt))
      dispatch(setskincolor(selectedSkin.alt))
      dispatch(sethairstyle(selectedHairType))
      dispatch(sethaircolor(selectedHair.alt))
      dispatch(settown(data.townName))
      dispatch(setchildname(data.childName))

      dispatch(setTeacher1(teacherName.teacher1))
      dispatch(setTeacher2(teacherName.teacher2))
      dispatch(setTeacher3(teacherName.teacher3))
      dispatch(setTeacher4(teacherName.teacher4))

      if(productToRoute.bookId === 'Teacher'){
      newBookDetail = {
        "gender": charactertoggle,
        "book": `bookID-${capitalizeFirstLetter(productToRoute.bookId)}`,
        "eye-color": eyeColorMapping[selectedEyes.alt] || selectedEyes.alt,
        "skin-color": skinColorMapping[selectedSkin.alt] || selectedSkin.alt,
        "hair-style": hairStyleMapping[selectedHairType] || selectedHairType,
        "hair-color": hairColorMapping[selectedHair.alt] || selectedHair.alt,
        "town": formattedTown,
        "child-name": formattedChildName,
        "teacher1" : teacherName.teacher1,
        "teacher2": teacherName.teacher2,
        "teacher3": teacherName.teacher3,
        "teacher4": teacherName.teacher4,
        "quantity": 1,
        "bookTempID": bookTempID,
      };
    }else{
      newBookDetail = {
        "gender": charactertoggle,
        "book": `bookID-${capitalizeFirstLetter(productToRoute.bookId)}`,
        "eye-color": eyeColorMapping[selectedEyes.alt] || selectedEyes.alt,
        "skin-color": skinColorMapping[selectedSkin.alt] || selectedSkin.alt,
        "hair-style": hairStyleMapping[selectedHairType] || selectedHairType,
        "hair-color": hairColorMapping[selectedHair.alt] || selectedHair.alt,
        "town": formattedTown,
        "child-name": formattedChildName,
        "quantity": 1,
        "bookTempID": bookTempID,
      };
    }
      isdone = true
    }

    if (isdone) {
      // dispatch(addToCart(product))
      if (productToRoute && uniqueIdToRoute) {
        productToRoute.bookTempID = bookTempID;
        productToRoute.variation = newBookDetail

        if(totalCount === 10 || totalCount >= 10){
          // setBulkPurchasepopUp(true);
          // alert("For Bulk order Contact us")
          setBulkPurchasepopUp(true)
          return;
      }
        dispatch(addToCart(productToRoute));
        dispatch(settoaststate(true));
        dispatch(setsize('sm'));
        dispatch(setvariant('soft'));
        dispatch(setcolor('success'));
        dispatch(setmessage('Item Added to your cart'));
        dispatch(setIcon('success'));
        dispatch(setuniqueId(uniqueIdToRoute));
      }
      navigate('/cart')
    }

  }

  return (
    <div className={CreateYourCharacterstyles.main}>
      
      <div className={CreateYourCharacterstyles.top}>
        <h1 className={CreateYourCharacterstyles.heading}>
          CREATE YOUR CHARACTER
        </h1>
        <div className={CreateYourCharacterstyles.container}>
          <div className={CreateYourCharacterstyles.charactermain}>
            <div className={CreateYourCharacterstyles.childName}>
              <div className={CreateYourCharacterstyles.charactertoggle}>
                <div
                  onClick={() => handleCharactertoggle("Boy")}
                  className={`${CreateYourCharacterstyles.charactertogglecommon
                    } ${charactertoggle === "Boy"
                      ? CreateYourCharacterstyles.charactertoggleboy
                      : ""
                    }`}
                >
                  Boy
                </div>
                <div
                  onClick={() => handleCharactertoggle("Girl")}
                  className={`${CreateYourCharacterstyles.charactertogglecommon
                    } ${charactertoggle === "Girl"
                      ? CreateYourCharacterstyles.charactertogglegirl
                      : ""
                    }`}
                >
                  Girl
                </div>
              </div>
            </div>

            <div className={CreateYourCharacterstyles.character}>
              {charactertoggle === "Girl" && (
                <div>
                  <img src={dress} alt="dress" />
                  <img src={selectedSkin.src} alt={selectedSkin.alt} loading="lazy"/>
                  <img src={selectedHair.src} alt={selectedHair.alt} loading="lazy"/>
                  <img src={selectedEyes.src} alt={selectedEyes.alt} loading="lazy"/>
                </div>
              )}

              {charactertoggle === "Boy" && (
                <div>
                  <img src={boydress} alt="dress" />
                  <img src={boyselectedSkin.src} alt={boyselectedSkin.alt} loading="lazy"/>
                  <img src={boyselectedHair.src} alt={boyselectedSkin.alt} loading="lazy"/>
                  <img src={boyselectedEyes.src} alt={boyselectedSkin.alt} loading="lazy"/>
                </div>
              )}
            </div>
            <div className={CreateYourCharacterstyles.inputContainer}>
              <div className={CreateYourCharacterstyles.inputContainerItem}>
                <input value={data.childName} onChange={handleChangeInput} name="childName" type="text" placeholder="Enter Child’s Name" className={CreateYourCharacterstyles.childNameinput} />
                {error.childName && <p style={{ color: "red" }}>{error.childName}</p>}
              </div>
              <div className={CreateYourCharacterstyles.inputContainerItem}>
                <input value={data.townName} onChange={handleChangeInput} name="townName" type="text" placeholder="Enter Town Name" className={CreateYourCharacterstyles.childNameinput} />
                {error.townName && <p style={{ color: "red" }}>{error.townName}</p>}
              </div>
              {productToRoute.bookId === 'Teacher' && <>
              <div className={CreateYourCharacterstyles.inputContainerItem}>
                <input value={teacherName.teacher1} onChange={handleChangeInputTeacher} name="teacher1" type="text" placeholder="Enter Class Teacher Name" className={CreateYourCharacterstyles.childNameinput} />
                {error.teacher1 && <p style={{ color: "red" }}>{error.teacher1}</p>}
              </div>
              <div className={CreateYourCharacterstyles.inputContainerItem}>
                <input value={teacherName.teacher2} onChange={handleChangeInputTeacher} name="teacher2" type="text" placeholder="Enter P.E. Teacher Name" className={CreateYourCharacterstyles.childNameinput} />
                {error.teacher2 && <p style={{ color: "red" }}>{error.teacher2}</p>}
              </div>
              <div className={CreateYourCharacterstyles.inputContainerItem}>
                <input value={teacherName.teacher3} onChange={handleChangeInputTeacher} name="teacher3" type="text" placeholder="Enter Teaching Assistant Name" className={CreateYourCharacterstyles.childNameinput} />
                {error.teacher3 && <p style={{ color: "red" }}>{error.teacher3}</p>}
              </div>
              <div className={CreateYourCharacterstyles.inputContainerItem}>
                <input value={teacherName.teacher4} onChange={handleChangeInputTeacher} name="teacher4" type="text" placeholder="Enter Head Teacher Name" className={CreateYourCharacterstyles.childNameinput} />
                {error.teacher4 && <p style={{ color: "red" }}>{error.teacher4}</p>}
              </div>
              </>
              }
            </div>

          </div>
          <div className={CreateYourCharacterstyles.characteroptionsdivs}>
            <div className={CreateYourCharacterstyles.extradiv}>
              <div className={CreateYourCharacterstyles.characterOptionLeft}>
                {
                  currectWidth &&
                  <button
                    onClick={() => setActiveOption('skin')}
                    className={activeOption === 'skin' ? CreateYourCharacterstyles.optionsbtn : CreateYourCharacterstyles.toggleButton}
                  >
                    Skin
                  </button>}

                <div style={{
                  // display: (currectWidth && activeOption === 'skin') ? '' : 'none'
                  display: (currectWidth ? (activeOption === 'skin' ? 'block' : 'none') : ''),
                  width: 'inherit'
                }}>

                  <div className={CreateYourCharacterstyles.skin}>
                    {charactertoggle === "Boy" && (
                      <div
                        className={CreateYourCharacterstyles.optionsdivinnercontainer}
                      >
                        <h3 className={CreateYourCharacterstyles.optionheading} style={{ paddingLeft: '15px' }}>
                          Skin:
                        </h3>
                        <div
                          className={CreateYourCharacterstyles.characteroptionskin}
                        >
                          {boyskinOptions.map((option,index) => (
                            <div key={`${option.id}${index}`}>
                              <div
                                style={{
                                  border:
                                    option.id === boyselectedSkin.id
                                      ? "2px solid #452565"
                                      : "",
                                }}
                                className={`${CreateYourCharacterstyles.commonskin} ${CreateYourCharacterstyles[option.alt]
                                  }`}
                                onClick={() => handleboyskin(option)}
                              ></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {charactertoggle === "Girl" && (
                      <div
                        className={CreateYourCharacterstyles.optionsdivinnercontainer}
                      >
                        <h3 className={CreateYourCharacterstyles.optionheading} style={{ paddingLeft: '15px' }}>
                          Skin:
                        </h3>
                        <div
                          className={CreateYourCharacterstyles.characteroptionskin}
                        >
                          {girlskinOptions.map((option,index) => (
                            <div key={`${option.id}${index}`}>
                              <div
                                style={{
                                  border:
                                    option.id === selectedSkin.id
                                      ? "2px solid #452565"
                                      : "",
                                }}
                                className={`${CreateYourCharacterstyles.commonskin} ${CreateYourCharacterstyles[option.alt]
                                  }`}
                                onClick={() => setSelectedSkin(option)}
                              ></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                </div>

                {
                  currectWidth &&
                  <button
                    onClick={() => setActiveOption('eye')}
                    className={activeOption === 'eye' ? CreateYourCharacterstyles.optionsbtn : CreateYourCharacterstyles.toggleButton}
                  >
                    eyes
                  </button>}

                <div style={{
                  // display: (currectWidth && activeOption === 'eye') ? '' : 'none'
                  display: (currectWidth ? (activeOption === 'eye' ? 'block' : 'none') : ''),
                  width: 'inherit'
                }}>
                  <div className={CreateYourCharacterstyles.eye}>
                    {charactertoggle === "Boy" && (
                      <div
                        className={CreateYourCharacterstyles.optionsdivinnercontainer}
                      >
                        <h3 className={CreateYourCharacterstyles.optionheading}>
                          Eyes:
                        </h3>
                        <div
                          className={CreateYourCharacterstyles.characteroptionskin}
                        >
                          {boyeyeOptions.map((option, index) => (
                            <div key={`${option.id}${index}`}>
                              <div
                                key={option.id}
                                style={{
                                  border:
                                    option.id === boyselectedEyes.id
                                      ? "2px solid #452565"
                                      : "",
                                }}
                                className={`${CreateYourCharacterstyles.commoneyehair} ${CreateYourCharacterstyles[option.alt]
                                  }`}
                                onClick={() => setBoyselectedEyes(option)}
                              >
                                <img src={option.src} alt={option.alt} className={CreateYourCharacterstyles.theimageeye} loading="lazy"/>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {charactertoggle === "Girl" && (
                      <div
                        className={CreateYourCharacterstyles.optionsdivinnercontainer}
                      >
                        <h3 className={CreateYourCharacterstyles.optionheading}>
                          Eyes:
                        </h3>
                        <div
                          className={CreateYourCharacterstyles.characteroptionskin}
                        >
                          {girleyeOptions.map((option, index) => (
                            <div key={`${option.id}${index}`}>
                              <div
                                key={option.id}
                                style={{
                                  border:
                                    option.id === selectedEyes.id
                                      ? "2px solid #452565"
                                      : "",
                                }}
                                className={`${CreateYourCharacterstyles.commoneyehair} ${CreateYourCharacterstyles[option.alt]
                                  }`}
                                onClick={() => setSelectedEyes(option)}
                              >
                                <img src={option.src} alt={option.alt} className={CreateYourCharacterstyles.theimageeyegirl} loading="lazy"/>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {
                  currectWidth &&
                  <button
                    onClick={() => setActiveOption('hair')}
                    className={activeOption === 'hair' ? CreateYourCharacterstyles.optionsbtn : CreateYourCharacterstyles.toggleButton}
                  >
                    hair
                  </button>}

                <div style={{
                  // display: (currectWidth && activeOption === 'hair') ? '' : 'none'
                  display: (currectWidth ? (activeOption === 'hair' ? 'block' : 'none') : ''),
                  width: 'inherit'
                }}>
                  <div className={CreateYourCharacterstyles.hair}>
                    <h3 className={CreateYourCharacterstyles.optionheading}>
                      Hair:
                    </h3>
                    {/* activehairOption, setActiveHairOption */}
                    <div style={{
                      display: (currectWidth ? (activeOption === 'skin' ? 'block' : 'none') : ''),
                      width: 'inherit'
                    }}>
                      <div className={CreateYourCharacterstyles.longshort}>
                        <div
                          style={{
                            background:
                              selectedHairType === 'short'
                                ? "#452565"
                                : "",
                            color:
                              selectedHairType === 'short'
                                ? "#fff"
                                : "",

                          }}
                          className={CreateYourCharacterstyles.short} onClick={() => handleHairTypeChange('short')}>Short</div>
                        <div
                          style={{
                            background:
                              selectedHairType === 'long'
                                ? "#452565"
                                : "",
                            color:
                              selectedHairType === 'long'
                                ? "#fff"
                                : "",
                          }}
                          className={CreateYourCharacterstyles.long} onClick={() => handleHairTypeChange('long')}>Long</div>
                        <div
                          style={{
                            background:
                              selectedHairType === 'afro'
                                ? "#452565"
                                : "",
                            color:
                              selectedHairType === 'afro'
                                ? "#fff"
                                : "",
                          }}
                          className={CreateYourCharacterstyles.long} onClick={() => handleHairTypeChange('afro')}>Afro</div>
                      </div>
                    </div>

                    {currectWidth && <div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <button onClick={handlePrevious} className={CreateYourCharacterstyles.shadowbtncls}>
                          <IoIosArrowBack size={24} />
                        </button>
                        <div style={{ margin: '0 20px', textAlign: 'center' }}>
                          <p style={{ fontSize: '18px', fontWeight: 'bold' }}>{currentHairtitle}</p>
                        </div>
                        <button onClick={handleNext} className={CreateYourCharacterstyles.shadowbtncls}>
                          <IoIosArrowForward size={24} />
                        </button>
                      </div>
                    </div>}


                    <div>
                      {charactertoggle === "Boy" && (
                        <div
                          className={
                            CreateYourCharacterstyles.optionsdivinnercontainer
                          }
                        >

                          <div
                            className={CreateYourCharacterstyles.characteroptionskin}
                          >
                            {hairOptions.map((option, index) => (
                              <div key={`${option.id}${index}`}>
                                <div
                                  key={option.id}
                                  style={{
                                    border:
                                      option.id === boyselectedHair.id
                                        ? "2px solid #452565"
                                        : "",
                                  }}
                                  className={`${CreateYourCharacterstyles.commoneyehair
                                    } ${CreateYourCharacterstyles[option.alt]}`}
                                  onClick={() => setBoyselectedHair(option)}
                                >
                                  <img src={option.src} alt={option.alt} className={CreateYourCharacterstyles.theimagehair} loading="lazy"/>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {charactertoggle === "Girl" && (
                        <div
                          className={
                            CreateYourCharacterstyles.optionsdivinnercontainer
                          }
                        >
                          <div
                            className={CreateYourCharacterstyles.characteroptionskin}
                          >
                            {girlhairnewOptions.map((option, index) => (
                              <div key={`${option.id}${index}`}>
                                <div
                                  key={option.id}
                                  style={{
                                    border:
                                      option.id === selectedHair.id
                                        ? "2px solid #452565"
                                        : "",
                                  }}
                                  className={`${CreateYourCharacterstyles.commoneyehair
                                    } ${CreateYourCharacterstyles[option.alt]}`}
                                  onClick={() => setSelectedHair(option)}
                                >
                                  <img src={option.src} alt={option.alt} className={CreateYourCharacterstyles.theimagehair} loading="lazy"/>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>

              </div>
            </div>
            {!currectWidth && <div className={CreateYourCharacterstyles.charcontactusbtn}>
              <CustomButton incoBg={true} icon={<IoIosArrowForward style={{ width: "20px", height: "20px" }} />} outerDivBg={"#BB82F9"} innerDivBg={"#AA76E2"} text={(productToRoute && uniqueIdToRoute) ? "Add To Cart" : "Next"} fontSize={"18px"} onClick={handleOpen} width={(productToRoute && uniqueIdToRoute) ? "180px" : "140px"} height={"50px"} topGradient={"rgb(156 102 212)"} bottomGradient={"rgb(189 158 220)"} />
            </div>}
          </div>
        </div>

        {currectWidth && <div className={CreateYourCharacterstyles.charcontactusbtnmobile}>
              <CustomButton incoBg={true} icon={<IoIosArrowForward style={{ width: "20px", height: "20px" }} />} outerDivBg={"#BB82F9"} innerDivBg={"#AA76E2"} text={(productToRoute && uniqueIdToRoute) ? "Add To Cart" : "Next"} fontSize={"18px"} onClick={handleOpen} width={(productToRoute && uniqueIdToRoute) ? "180px" : "140px"} height={"50px"} topGradient={"rgb(156 102 212)"} bottomGradient={"rgb(189 158 220)"} />
            </div>}


        {/* <div onClick={handleAddToCart}> */}
        {/* <NextBtnCharacter /> */}

        {/* </div> */}
      </div>
      <div className={CreateYourCharacterstyles.cyccharacters}>
      <img src={characters} alt='characters' loading="lazy"/>
      </div>
      <div className={CreateYourCharacterstyles.bottomcontent}>
       
        <div className={CreateYourCharacterstyles.characterparagraph}>
          We understand that every little hero is a one-in-a-billion unique and
          amazing creation, and comes in all manner of magical shapes, sizes and
          appearance. If you cannot create who you want from the options on this
          page, please contact us with your request and we can see if it is
          something we can customise – there may be a premium cost attached. We
          can’t promise you we can, but we’ll do our very best to accommodate your
          requests!<br/> With Love – the I Want to Be creative team.
        </div>
        <div className={CreateYourCharacterstyles.charcontactusbtn}>
          <CustomButton incoBg={true} icon={<IoIosArrowForward style={{ width: "20px", height: "20px" }} />} outerDivBg={"#BB82F9"} innerDivBg={"#AA76E2"} text={"Contact Us"} fontSize={"14px"} onClick={() => navigate('/contactus')} width={"150px"} height={"50px"} topGradient={"#BB82F9"} bottomGradient={"#9265C2"} />
        </div>
      </div>

      <div className={CreateYourCharacterstyles.bottom}>
        <div className={CreateYourCharacterstyles.Footer}>
          <Footer />
        </div>
      </div>

      {!error.childName && !error.townName && <ConfirmModal isOpen={open} onClose={handleClose} onConfirm={handleConfirm} />}

      {
                bulkPurchasepopUp && 
                <div className={CreateYourCharacterstyles.logoutpopup}>
                    <div className={CreateYourCharacterstyles.logoutpopupbox}>
                        {/* <span onClick={() => setBulkPurchasepopUp(false)} className={CreateYourCharacterstyles.closeicon}><span>&times;</span></span> */}
                        <p className={CreateYourCharacterstyles.textparagraph}>For Bulk Purchse Please Contact Us!</p>
                        <div className={CreateYourCharacterstyles.btns}>
                            <span onClick={() => setBulkPurchasepopUp(false)} className={CreateYourCharacterstyles.cancel}>Cancel</span>
                            <span onClick={() => navigate('/contactus')} className={CreateYourCharacterstyles.logout}>Contact Us</span>
                        </div>
                    </div>
                </div>
            }
    </div>
  );
};

export default CreateYourCharacter;
