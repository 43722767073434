import React from 'react';
import { Modal, Box, Typography, IconButton, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import orderconfirm from '../../Assets/orderconfirm.png'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const OrderConfirmationModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="order-confirmation-modal-title"
      aria-describedby="order-confirmation-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <img
          src={orderconfirm}
          alt="Order Confirmed"
          style={{
            width: '30%',
            margin: '0 auto',
            height: 'auto',
            display: 'block',
          }}
        />
        <Typography
          id="order-confirmation-modal-title"
          variant="h6"
          sx={{ marginTop: 2, fontSize: '1rem' }}
        >
          Your Order is Placed
        </Typography>
        <Typography
          id="order-confirmation-modal-description"
          sx={{ fontSize: '0.8rem', marginTop: 1 }}
        >
          You will be receiving a confirmation email with order details.
        </Typography>
      </Box>
    </Modal>
  );
};

export default OrderConfirmationModal;
