import React, { useState } from 'react';
import styles from './AdminLogin.module.css';
import { useNavigate } from 'react-router-dom';
import { fetchAuthSession, getCurrentUser, signIn, signOut } from 'aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { clearcart } from '../features/cart/cartSlice';
import { setcolor, setmessage, setsize, settoaststate, setvariant } from '../features/toastSlice';
import { Oval } from 'react-loader-spinner';
import { useAdmin } from '../context/AdminContext';

const AdminLogin = () => {

    const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();  // Use useNavigate

  const dispatch = useDispatch();

  const { setAdmindetails } = useAdmin();

  const handleLogin = async (e) => {
    e.preventDefault();
    try{
    setIsloading(true)
    const user = await signIn({
        username: username,
        password: password
        //   options: {
        //   authFlowType: 'CUSTOM_WITH_SRP'
        // }
      });

      console.log("user",user)
      if (user) {
        const authUser = await getCurrentUser();
        const userData = await fetchAuthSession();

        // console.log("authUser ==>",authUser)
        // console.log("userData ==>",userData)

        // Check if the user belongs to the 'Admin' group
      const payloaddata = userData?.tokens?.idToken?.payload;
      const groups = payloaddata["cognito:groups"] || [];
        // console.log("groups",groups)

      if (groups.includes("Admin")) {
        
        setAdmindetails(authUser.signInDetails.loginId)
        // localStorage.setItem(
        //     "bookstoreadminloginusername777",
        //     authUser.signInDetails.loginId
        //   );

          setTimeout(() => {
            setIsloading(false)
          // window.location.reload();
          // navigate("/admin-dashboard");
        navigate("/admin-dashboard");
        }, 100);
        
      }else{
        setError("users not found");
        await signOut();
        localStorage.removeItem("bookstoreadminloginusername777");
        localStorage.removeItem("bookstorecurrentusername");
        localStorage.removeItem("bookstorecurrentloginuser");
        localStorage.removeItem("CurrentFullName");
        await dispatch(clearcart());
        localStorage.removeItem("orderData");
        localStorage.removeItem("cart");

        dispatch(settoaststate(true));
        dispatch(setsize("sm"));
        dispatch(setvariant("solid"));
        dispatch(setcolor("danger"));
        dispatch(setmessage("User not found"));

        setIsloading(false)
        return;
      }
      }
    }catch(err){
      setIsloading(false)
      setUsername('')
      setPassword('')
      
      console.log("Err",err.message)

      dispatch(settoaststate(true));
      dispatch(setsize("sm"));
      dispatch(setvariant("solid"));
      dispatch(setcolor("danger"));
      dispatch(setmessage(err.message));
      console.error("Err",err)
    }

    // Simple mock authentication
    // if (username === 'admin' && password === 'password123') {
    //   navigate('/admin-dashboard');  // Redirect to admin dashboard
    // } else {
    //   setError('Invalid username or password');
    // }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginBox}>
        <h2 className={styles.title}>Admin Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className={styles.inputField}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.inputField}
            required
          />
          {error && <p className={styles.errorMessage}>{error}</p>}
          <button type="submit" className={styles.loginButton}>Login</button>
        </form>
      </div>
      {isloading && <div className={styles.loader}>
      <Oval
        visible={true}
        height="80"
        width="80"
        color="#fff"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass=""
        />
      </div>}
    </div>
  )
}

export default AdminLogin