import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

// Function to fetch client ID from backend
const fetchClientId = async () => {
  try {
    const response = await fetch('http://localhost:8888/api/paypal-client-id');
    if (!response.ok) {
      throw new Error('Failed to fetch client ID');
    }
    const data = await response.json();
    return data.clientId;
  } catch (error) {
    console.error('Error fetching client ID:', error);
    return null;
  }
};

export default function Payment() {
  const [clientId, setClientId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch client ID from backend when component mounts
  useEffect(() => {
    const getClientId = async () => {
      const id = await fetchClientId();
      setClientId(id);
      setIsLoading(false); // Set loading to false after fetching client ID
    };
    getClientId();
  }, []);

  // Create order callback
  const createOrder = async () => {
    try {
      const response = await fetch('http://localhost:8888/api/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cart: [
            {
              id: 'YOUR_PRODUCT_ID',
              quantity: 'YOUR_PRODUCT_QUANTITY',
              amount: '0.1', // Adjust as necessary
            },
          ],
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create order');
      }

      const orderData = await response.json();
      if (!orderData.id) {
        throw new Error('Order ID not found in response');
      }
      return orderData.id;
    } catch (error) {
      console.error('Error creating order:', error);
      // alert('Could not initiate PayPal Checkout');
      throw error;
    }
  };

  // On approve callback
  const onApprove = async (data) => {
    try {
      const response = await fetch(`http://localhost:8888/api/orders/${data.orderID}/capture`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to capture order');
      }

      const orderData = await response.json();
      const transaction = orderData?.purchase_units?.[0]?.payments?.captures?.[0];

      if (!transaction || transaction.status === 'DECLINED') {
        // alert(`Transaction ${transaction.status}: ${transaction.id}`);
      } else {
        // alert(`Transaction ${transaction.status}: ${transaction.id}`);
      }
    } catch (error) {
      console.error('Error capturing order:', error);
      // alert('Sorry, your transaction could not be processed');
    }
  };

  // Show loading message while fetching client ID
  if (isLoading) {
    return <div>Loading PayPal...</div>;
  }

  // Show error message if client ID is not available
  if (!clientId) {
    return <div>Error loading PayPal</div>;
  }

  return (
    <PayPalScriptProvider options={{ clientId }}>
      <div>
        <h1>PayPal Checkout Integration</h1>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </div>
    </PayPalScriptProvider>
  );
}




// import React, { useEffect, useState } from "react";

// import {
//   PayPalScriptProvider,
//   usePayPalCardFields,
//   PayPalCardFieldsProvider,
//   PayPalCardFieldsForm,
//   PayPalButtons,
// } from "@paypal/react-paypal-js";

// const fetchClientId = async () => {
//   try {
//     const response = await fetch('http://localhost:8888/api/paypal-client-id');
//     if (!response.ok) {
//       throw new Error('Failed to fetch client ID');
//     }
//     const data = await response.json();
//     return data.clientId;
//   } catch (error) {
//     console.error('Error fetching client ID:', error);
//     return null;
//   }
// };

// export default function Payment() {
//   const [isPaying, setIsPaying] = useState(false);
//   const [billingAddress, setBillingAddress] = useState({
//     addressLine1: "",
//     addressLine2: "",
//     adminArea1: "",
//     adminArea2: "",
//     countryCode: "",
//     postalCode: "",
//   });

//     const [clientId, setClientId] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   // Fetch client ID from backend when component mounts
//   useEffect(() => {
//     const getClientId = async () => {
//       const id = await fetchClientId();
//       setClientId(id);
//       setIsLoading(false); // Set loading to false after fetching client ID
//     };
//     getClientId();
//   }, []);
//   async function createOrder(data) {
//     return fetch("http://localhost:8888/api/orders", {
//       method: "POST",
//       // Use the "body" parameter to optionally pass additional order information
//       body: JSON.stringify({
//         cart: [
//           {
//             sku: "1blwyeo8",
//             quantity: 2,
//           },
//         ],
//         card: {
//           attributes: {
//             verification: {
//               method: "SCA_ALWAYS",
//             },
//           },
//         },
//       }),
//     })
//       .then((response) => response.json())
//       .then((order) => order.id)
//       .catch((err) => {
//         console.error(err);
//       });
//   }

//   function onApprove(data) {
//     return fetch(`http://localhost:8888/api/orders/${data.orderID}/capture`, {
//       method: "POST",
//     })
//       .then((response) => response.json())
//       .then((orderData) => {
//         // Successful capture!
//       })
//       .catch((err) => {});
//   }

//   function onError(error) {
//     // Do something with the error from the SDK
//   }

//   function handleBillingAddressChange(field, value) {
//     setBillingAddress((prev) => ({
//       ...prev,
//       [field]: value,
//     }));
//   }

//   return (
//     <PayPalScriptProvider
//       options={{
//         clientId: clientId,
//         components: "card-fields,buttons",
//       }}
//     >
//       <PayPalButtons
//         createOrder={createOrder}
//         onApprove={onApprove}
//         onError={onError}
//       />
//       <PayPalCardFieldsProvider
//         createOrder={createOrder}
//         onApprove={onApprove}
//         onError={onError}
//       >
//         <PayPalCardFieldsForm />
//         <input
//           type="text"
//           id="card-billing-address-line-2"
//           name="card-billing-address-line-2"
//           placeholder="Address line 1"
//           onChange={(e) =>
//             handleBillingAddressChange("addressLine1", e.target.value)
//           }
//         />
//         <input
//           type="text"
//           id="card-billing-address-line-2"
//           name="card-billing-address-line-2"
//           placeholder="Address line 2"
//           onChange={(e) =>
//             handleBillingAddressChange("addressLine2", e.target.value)
//           }
//         />
//         <input
//           type="text"
//           id="card-billing-address-admin-area-line-1"
//           name="card-billing-address-admin-area-line-1"
//           placeholder="Admin area line 1"
//           onChange={(e) =>
//             handleBillingAddressChange("adminArea1", e.target.value)
//           }
//         />
//         <input
//           type="text"
//           id="card-billing-address-admin-area-line-2"
//           name="card-billing-address-admin-area-line-2"
//           placeholder="Admin area line 2"
//           onChange={(e) =>
//             handleBillingAddressChange("adminArea2", e.target.value)
//           }
//         />
//         <input
//           type="text"
//           id="card-billing-address-country-code"
//           name="card-billing-address-country-code"
//           placeholder="Country code"
//           onChange={(e) =>
//             handleBillingAddressChange("countryCode", e.target.value)
//           }
//         />
//         <input
//           type="text"
//           id="card-billing-address-postal-code"
//           name="card-billing-address-postal-code"
//           placeholder="Postal/zip code"
//           onChange={(e) =>
//             handleBillingAddressChange("postalCode", e.target.value)
//           }
//         />
//         {/* Custom client component to handle card fields submission */}
//         <SubmitPayment
//           isPaying={isPaying}
//           setIsPaying={setIsPaying}
//           billingAddress={billingAddress}
//         />
//       </PayPalCardFieldsProvider>
//     </PayPalScriptProvider>
//   );
// }

// const SubmitPayment = ({ isPaying, setIsPaying, billingAddress }) => {
//   const { cardFieldsForm, fields } = usePayPalCardFields();

//   const handleClick = async () => {
//     if (!cardFieldsForm) {
//       const childErrorMessage =
//         "Unable to find any child components in the <PayPalCardFieldsProvider />";

//       throw new Error(childErrorMessage);
//     }
//     const formState = await cardFieldsForm.getState();

//     if (!formState.isFormValid) {
//       return alert("The payment form is invalid");
//     }
//     setIsPaying(true);

//     cardFieldsForm.submit({ billingAddress }).catch((err) => {
//       setIsPaying(false);
//     });
//   };

//   return (
//     <button
//       className={isPaying ? "btn" : "btn btn-primary"}
//       style={{ float: "right" }}
//       onClick={handleClick}
//     >
//       {isPaying ? <div className="spinner tiny" /> : "Pay"}
//     </button>
//   );
// };             
