// UserContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a context with default values
const UserContext = createContext({
  fullName: '',
  setFullName: () => {}
});

// Provider component
export const UserProvider = ({ children }) => {
    const [fullName, setFullName] = useState(() => {
        const storedFullName = localStorage.getItem("CurrentFullName");
        return storedFullName || null; // Default to empty string if not found
      });
    

  return (
    <UserContext.Provider value={{ fullName, setFullName }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext);
