import { configureStore } from '@reduxjs/toolkit'
import cartReducer from "./features/cart/cartSlice"
import toastSlice from './features/toastSlice'
import characterSlice from './features/characterSlice'

export const store = configureStore({
  reducer: {
    cart:cartReducer,
    toastSliceInfo: toastSlice,
    characterinfo: characterSlice
  },
})