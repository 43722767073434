import { createSlice } from "@reduxjs/toolkit";

const toastSlice = createSlice({
    name: 'toastSliceInfo',
    initialState: {
        uniqueId: null,
        toaststate: false,
        size:"sm",
        variant:"soft",
        color:"success",
        message: '',
        iCon: null
    },
    reducers: {
        settoaststate(state, action) {
            state.toaststate = action.payload;
        },

        setsize(state, action) {
            state.size = action.payload;
        },

        setvariant(state, action) {
            state.variant = action.payload;
        },

        setcolor(state, action) {
            state.color = action.payload;
        },

        setmessage(state, action) {
            state.message = action.payload;
        },
        setIcon(state, action) {
            state.iCon = action.payload;
        },
        setuniqueId(state, action) {
            state.uniqueId = action.payload;
        },
    },
})

export const { iCon, toaststate, size, variant, color,  message,uniqueId, settoaststate,  setsize, setvariant, setcolor, setmessage, setIcon, setuniqueId} = toastSlice.actions;
export default toastSlice.reducer;