import { createSlice } from "@reduxjs/toolkit";

function getNumericPrice(price) {
    if (typeof price === "string") {
        let numericPrice = price.replace(/[^0-9.]/g, '');
        return parseFloat(numericPrice) || 0; // Fallback to 0 if parsing fails
    }
    return Number(price) || 0; // Fallback to 0 if value is not a number
}

function calculateTax(totalCount) {
    if (totalCount === 1) return 3.99;
    if (totalCount === 2) return 4.49;
    if (totalCount === 3) return 5.49;
    if (totalCount >= 4 && totalCount <= 6) return 6.99;
    if (totalCount >= 7 && totalCount <= 10) return 8.99;
    if (totalCount > 10) return 0; // Direct to contact us for bulk purchases
    return 0;
}

// Load cart from localStorage
const loadCartFromLocalStorage = () => {
    const cartData = localStorage.getItem('cart');
    if (cartData) {
        return JSON.parse(cartData);
    }
    return {
        cart: [],
        totalBasePrice: 0,
        totalPrice: 0,
        finalTotalPrice: 0,
        totalCount: 0,
        tax: 0,
        discount: 0,
        voucherPromoCode: '',
        isPromoapplied: false,
        discountType: ''
    };
};

const calculateNewPrice = (totalPriceofCart, discountPercentage) => {
    const discountAmount = totalPriceofCart * (discountPercentage / 100);
    const newPrice = totalPriceofCart - discountAmount;
    return newPrice  // Returns the new price with two decimal points
}

// Save cart to localStorage
const saveCartToLocalStorage = (state) => {
    localStorage.setItem('cart', JSON.stringify(state));
};

const initialState = loadCartFromLocalStorage();

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const existingItemIndex = state.cart.findIndex(cartItem => cartItem.bookTempID === action.payload.bookTempID);
            if (existingItemIndex !== -1) {
                // Item already in cart, update quantity
                state.cart[existingItemIndex].quantity += 1;
            } else {
                // New item, add to cart
                state.cart.push({ ...action.payload, quantity: 1 });
            }
            state.totalCount += 1;
            const newBasePrice = Number(state.totalBasePrice) + getNumericPrice(action.payload.price);

            state.totalBasePrice = newBasePrice

            state.finalTotalPrice = newBasePrice

            // const ActualDiscount = Number(state.discount.toFixed(2));
            const discountValue = Number(state.discount);  // Convert to number if it's not already
            const ActualDiscount = isNaN(discountValue) ? 0 : discountValue
            
            if(state.isPromoapplied){

                if(state.discountType === 'OnlyItems'){
                const newPrice = calculateNewPrice(state.totalBasePrice, ActualDiscount);
                // console.log("newPricediscount",newPrice)
                // const afterdiscount =  ActualDiscount/state.totalBasePrice
                state.totalPrice = Number(newPrice)
                state.tax = calculateTax(state.totalCount);
                state.finalTotalPrice = Number(newPrice) + calculateTax(state.totalCount);

                saveCartToLocalStorage(state);
                }
                if(state.discountType === 'Total'){
                    const newPrice = calculateNewPrice((state.totalBasePrice + calculateTax(state.totalCount)), ActualDiscount);
                    // console.log("newPricediscount",newPrice)
                    // const afterdiscount =  ActualDiscount/state.totalBasePrice
                    state.totalPrice = Number(newPrice)
                    state.tax = calculateTax(state.totalCount);
                    state.finalTotalPrice = Number(newPrice);
    
                    saveCartToLocalStorage(state);
                }

            }else{
                const newPrice = Number(state.totalPrice) + getNumericPrice(action.payload.price);
                // console.log("newPrice",newPrice)
                state.totalPrice = Number(newPrice)
                state.tax = calculateTax(state.totalCount);

                state.finalTotalPrice = Number(newPrice) + calculateTax(state.totalCount);

                saveCartToLocalStorage(state);
            }
        },
        removeFromCart: (state, { payload }) => {
            const existingItemIndex = state.cart.findIndex(cartItem => cartItem.bookId === payload.bookId);
            if (existingItemIndex !== -1) {
                const item = state.cart[existingItemIndex];
                // console.log("state.cart.length",state.cart.length)
                if (item.quantity === 1 && state.cart.length === 1) {
                    state.discount = 0;
                    state.isPromoapplied = false;
                    state.voucherPromoCode = '';
                }

                item.quantity -= 1;
                if (item.quantity <= 0) {
                    state.cart.splice(existingItemIndex, 1);
                    // state.isPromoapplied = true;
                    // console.log("item.quantity",item.quantity)
                }
                
                state.totalCount -= 1;
                const newBasePrice = Number(state.totalBasePrice) - getNumericPrice(payload.price);
                state.totalBasePrice = Number(newBasePrice);

                if(state.isPromoapplied){
                    if(state.discountType === 'OnlyItems'){
                        state.totalPrice = Number((state.totalBasePrice * (1 - state.discount / 100)))
                        state.finalTotalPrice = Number((state.totalBasePrice * (1 - state.discount / 100))) + calculateTax(state.totalCount);
                    }
                    if(state.discountType === 'Total'){
                        state.totalPrice = Number((state.totalBasePrice * (1 - state.discount / 100)))
                        state.finalTotalPrice = Number(((state.totalBasePrice  + calculateTax(state.totalCount)) * (1 - state.discount / 100)));
                    }
                }else{
                    state.totalPrice = Number(state.totalBasePrice);
                    state.finalTotalPrice = Number(state.totalBasePrice) + calculateTax(state.totalCount);
                }

                state.tax = calculateTax(state.totalCount);
                saveCartToLocalStorage(state);
            }
        },
        applyDiscount: (state, { payload }) => {
            // Apply discount based on the base price (before any discount)

            state.discount = payload.discount; // Assume payload.discount is the percentage discount
            state.discountType = payload.discountType;
    
            console.log("payload",payload)
            console.log("state.discountType",state.discountType)
            // if(state.discountType === "OnlyItems"){
            

            state.isPromoapplied = true;
            state.voucherPromoCode = payload.voucherPromoCode;

            state.tax = calculateTax(state.totalCount);
            if(state.discountType === 'OnlyItems'){
                const discountAmount = (state.totalBasePrice * payload.discount) / 100;

                state.totalPrice = Number((state.totalBasePrice - discountAmount)); // Apply discount
                state.finalTotalPrice = state.totalPrice + calculateTax(state.totalCount)
            }
           
            if(state.discountType === 'Total'){
                const discountAmount = ((state.totalBasePrice + calculateTax(state.totalCount)) * payload.discount) / 100;

                state.totalPrice = Number(((state.totalBasePrice + calculateTax(state.totalCount)) - discountAmount)); // Apply discount
                console.log("state.totalPrice",state.totalPrice)
                state.finalTotalPrice = state.totalPrice
            }

            saveCartToLocalStorage(state);
        },
        clearVoucher: (state) => {
            state.isPromoapplied = false;
            state.totalPrice = Number(state.totalBasePrice);
            state.finalTotalPrice = Number(state.totalBasePrice) + calculateTax(state.totalCount);
            state.voucherPromoCode = '';
            state.discount = 0;
            state.discountType = ''
            saveCartToLocalStorage(state);
        },
        clearcart: (state) => {
            // Clear the cart
            state.cart = [];
            state.totalBasePrice = 0;
            state.finalTotalPrice = 0;
            state.totalPrice = 0;
            state.totalCount = 0;
            state.tax = 0;
            state.discount = 0;
            state.discountType = ''
            state.isPromoapplied = false;
            state.voucherPromoCode = '';
            saveCartToLocalStorage(state);
        }
    }
});

export const { addToCart, removeFromCart, applyDiscount, clearVoucher, clearcart } = cartSlice.actions;
export default cartSlice.reducer;
