import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import ResetPasswordstyles from "./ResetPassword.module.css";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import {
  color,
  setcolor,
  setmessage,
  setsize,
  settoaststate,
  setvariant,
} from "../../../features/toastSlice";

const ResetPassword = ({ setForgotPasswordClicked }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");

  // const [resetinfoerror, setResetinfoerror] = useState({
  //   verificationCode: false,
  //   newPassword: false,
  //   confirmPassword: false,
  //   passwordnotmatch: false
  // });

  const [usernameerror, setUsernameerror] = useState(false);


  const dispatch = useDispatch();

  const [forgotemailsent, setForgotemailsent] = useState(false);

  const [msg, setMsg] = useState("");

  const [verificationmsg, setVerificationmsg] = useState("");

  const [confirmmsg, setConfirmmsg] = useState("");


  const [resetinfo, setResetinfo] = useState({
    verificationCode: "",
    newPassword: "",
    confirmPassword: "",
  });



  const handlePasswordResetSubmit = async () => {
    // if(resetinfo.newPassword !== resetinfo.confirmPassword){
    //   setMsg("Password & Confirm Password should be Match");
    //   return;
    // }
    // confirmmsg, setConfirmmsg
    if (!resetinfo.verificationCode) {
      setVerificationmsg("Password enter verification code");
    }
    if (resetinfo.newPassword && resetinfo.confirmPassword !== resetinfo.newPassword) {
      setConfirmmsg("Password & Confirm Password should be same");
      return
    }
    if (!resetinfo.newPassword) {
      setMsg("Password enter password");
      return;
    }

    if (resetinfo.newPassword.length < 8) {
      setMsg("Password should be Greater than 8 Character");
      return;
    }
    setLoading(true);

    const confirmationCode = resetinfo.verificationCode;
    const newPassword = resetinfo.newPassword;

    try {
      
      await confirmResetPassword({ username, confirmationCode, newPassword });
      // setAlertmessage({
      //   type: "success",
      //   message: "Password reset successfully.",
      //   visible: true,
      // });

      // props.setResetpopup(false)
      setLoading(false);

            // console.log("output",output)
      dispatch(settoaststate(true));
      dispatch(setsize('lg'));
      dispatch(setvariant('solid'));
      dispatch(setcolor('success'));
      dispatch(setmessage('Password reset succesfully!'));
      // setPassupsuccesfully(true)
      setForgotPasswordClicked(false);
    } catch (error) {
      dispatch(settoaststate(true));
      dispatch(setsize('lg'));
      dispatch(setvariant('solid'));
      dispatch(setcolor('warning'));
      dispatch(setmessage("Something went wrong."));
      console.log("error",error)
    } finally {
      setLoading(false);
    }
  };

  const handleresetinfoChange = (event) => {
    const { name, value } = event.target;
    setMsg("");
    setVerificationmsg("")
    setConfirmmsg("")
    // For other input fields, update the form data state directly
    setResetinfo({ ...resetinfo, [name]: value });
  };

  const handleForgotPasswordSubmit = async () => {
    if (!username) {
      setUsernameerror(true);
      return;
    }
    setLoading(true);
    try {
      const output = await resetPassword({ username });
      // console.log("output",output)
      // dispatch(settoaststate(true));
      // dispatch(setsize('lg'));
      // dispatch(setvariant('solid'));
      // dispatch(setcolor('success'));
      // dispatch(setmessage('Verification email is sent to your email'));
      setForgotemailsent(true);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      dispatch(settoaststate(true));
      dispatch(setsize('lg'));
      dispatch(setvariant('solid'));
      dispatch(setcolor('waring'));
      dispatch(setmessage('User Does not exist'));
    }
    setLoading(false);
  };

  const handleCloseemailsentmodel = () => {
    // console.log("props",props)

    setForgotPasswordClicked(false);
  };

  return (
    <div className={ResetPasswordstyles.OTPConfirmationModelmain}>
      <div className={ResetPasswordstyles.confirmationmodelsignin}>
        <span
          onClick={handleCloseemailsentmodel}
          style={{ marginLeft: "auto", fontSize: "18px", cursor: "pointer" }}
        >
          &#10539;
        </span>

        {!forgotemailsent && (
          <div className={ResetPasswordstyles.container}>
            <h2 className={ResetPasswordstyles.sentemailheading}>
              Enter Your Email
            </h2>
            <div className={ResetPasswordstyles.sentemail}>
              <input
                type="text"
                placeholder="Enter Email"
                name="email"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                required
              />
              {usernameerror && (
                <p
                  style={{
                    fontSize: "12px",
                    width: "100%",
                    color: "red",
                    textAlign: "left",
                    padding: "0.2rem",
                  }}
                >
                  Please enter you email
                </p>
              )}
              <span
                className={ResetPasswordstyles.submit}
                onClick={handleForgotPasswordSubmit}
              >
                {/* Submit */}
                {loading ? (
                  <TailSpin
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                  />
                ) : (
                  "Submit"
                )}
              </span>
            </div>
          </div>
        )}

        {forgotemailsent && (
          <div className={ResetPasswordstyles.otpform}>
            <h2 className={ResetPasswordstyles.otpheading}>
              Verification Code is Sent to You Email
            </h2>
            <span className={ResetPasswordstyles.otpmsg}>Check Your Email</span>
            <div className={ResetPasswordstyles.signinform}>
              <input
                type="text"
                placeholder="Enter Email"
                name="email"
                value={username}
                required
                disabled
                className={ResetPasswordstyles.resetinput}
              />
              
              <div>
                <input
                  type="text"
                  id="verificationCode"
                  name="verificationCode"
                  placeholder="verification Code"
                  value={resetinfo.verificationCode}
                  onChange={handleresetinfoChange}
                  required
                />
              </div>
              <span style={{ fontSize: "12px", color: "red" }}>{verificationmsg}</span>
              <div>
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="New Password"
                  value={resetinfo.newPassword}
                  onChange={handleresetinfoChange}
                  required
                />
                <span style={{ fontSize: "12px", color: "red" }}>{msg}</span>
              </div>
              <div>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={resetinfo.confirmPassword}
                  onChange={handleresetinfoChange}
                  required
                />
                <span style={{ fontSize: "12px", color: "red" }}>{confirmmsg}</span>
              </div>
            </div>

            <div
              className={`${ResetPasswordstyles.miansociallogincard} ${ResetPasswordstyles.signin}`}
              onClick={handlePasswordResetSubmit}
            >
              <span className={ResetPasswordstyles.resetbtn}>
                {loading ? (
                  <TailSpin
                  height="20"
                  width="20"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  visible={true}
                />
                ) : (
                  "Reset"
                )}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
