import React from 'react'
import styles from './AllCustomerOrders.module.css';

const DetailsComponent = ({order,setShowDetail}) => {
    console.log("order",order[0])
    const handleClose = () => {
      setShowDetail(false)
    }
  return (
    <div className={styles.logoutpopup}>
    <div className={styles.logoutpopupbox}>
      <div className={styles.closeicon} onClick={handleClose}>
        <span>&times;</span>
      </div>
      
      <h3 className={styles.sectionTitle}>Items in Order:</h3>
      <div className={styles.card}>
        {/* Left Side - Items Section */}
        <div className={styles.cardleft}>

          <div className={styles.maincontent}>
          {/* Right Side - Order and Payment Details */}
            <div className={styles.content}>
              <h6>Order Details:</h6>
              <p><span className={styles.subheading}>Order ID: </span>{order.PK}</p>
              {order.currentStatus && <p><span className={styles.subheading}>Order status: </span>{order.currentStatus.status}</p>}
              {order.history && order.history.some(item => item.status === 'shipped') && (
                <p>
                  <span className={styles.subheading}>Tracking Number: </span>
                  {
                    // Find the first 'shipped' status and display its tracking number
                    order.history.find(item => item.status === 'shipped').trackingNumber
                  }
                </p>
              )}
              <p><span className={styles.subheading}>Customer: </span>{order.customer.name}</p>
              <p><span className={styles.subheading}>Order Date: </span>{new Date(order.orderDate).toLocaleString()}</p>
                <hr className={styles.lineaboveheading}/>
              <h6>Payment Details:</h6>
              <p><span className={styles.subheading}>Status: </span>{order.paymentDetails.status}</p>
              <p><span className={styles.subheading}>Total Amount: </span>£{parseFloat(order.paymentDetails.totalAmount).toFixed(2)}</p>
              <p><span className={styles.subheading}>Tax: </span>£{parseFloat(order.paymentDetails.tax).toFixed(2)}</p>
              <p><span className={styles.subheading}>Item Price: </span>£{parseFloat(order.paymentDetails.itemPrice).toFixed(2)}</p>
              <p><span className={styles.subheading}>Payment ID: </span>{order.paymentDetails.paymentID}</p>
                <hr className={styles.lineaboveheading}/>
              <h6>Shipping Details:</h6>
              <p><span className={styles.subheading}>Name: </span>
                {order.shippingDetail.firstName} {order.shippingDetail.lastName}
              </p>
              <p><span className={styles.subheading}>Address: </span>{order.shippingDetail.AddressLine1}, {order.shippingDetail.City}, {order.shippingDetail.CountyTown}, {order.shippingDetail.Postcode}</p>
              <p><span className={styles.subheading}>Email: </span>{order.shippingDetail.Email}</p>
              <p><span className={styles.subheading}>Phone: </span>{order.shippingDetail.PhoneNumber}</p>
              <p><span className={styles.subheading}>Country: </span>{order.shippingDetail.isoCountry}</p>
            </div>

            {/* <div className={styles.cardleft}>
            <h3 className={styles.sectionTitle}>Items in Order:</h3> */}
            <div className={styles.allitems}>
              
            {order.item.map((item, index) => (
              <div key={index} className={styles.itemDetails}>
                <img
                  src={item.imageUrl}
                  alt={item.title}
                  className={styles.image}
                />
                <div className={styles.bookdetials}>
                  <span className={styles.title}>{item.title}</span>
                  <p className={styles.category}><span className={styles.subheading}>Book ID: </span>{item.bookId}</p>
                  <p className={styles.price}><span className={styles.subheading}>Price: </span>£{parseFloat(item.price).toFixed(2)}</p>
                  <p className={styles.quantity}><span className={styles.subheading}>Quantity: </span>{item.quantity}</p>
                  <p className={styles.rating}><span className={styles.subheading}>Rating: </span>{item.rating} ⭐</p>
                </div>
              </div>
            ))}
            {/* </div> */}
            </div>
          </div>
        </div>

        
      </div>
{/* 
      <div className={styles.btns}>
        <span className={styles.cancel} onClick={() => setShowdetail(false)}>Close</span>
      </div> */}
    </div>
  </div>
  )
}

export default DetailsComponent