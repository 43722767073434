import React, { createContext, useState, useContext } from 'react';

// Create a context with default values
const AdminContext = createContext({
  admindetails: '',
  setAdmindetails: () => {}
});

// Provider component
export const AdminProvider = ({ children }) => {
  const [admindetails, setAdmindetailsState] = useState(() => {
    const storedAdmindetails = localStorage.getItem('bookstoreadminloginusername777');
    return storedAdmindetails || null; // Default to empty string if not found
  });

  // This function updates both state and localStorage
  const setAdmindetails = (loginId) => {
    setAdmindetailsState(loginId);
    localStorage.setItem('bookstoreadminloginusername777', loginId);
  };

  return (
    <AdminContext.Provider value={{ admindetails, setAdmindetails }}>
      {children}
    </AdminContext.Provider>
  );
};

// Custom hook to use the AdminContext
export const useAdmin = () => useContext(AdminContext);
