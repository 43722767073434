import React, { useState, useEffect } from 'react';
import { Skeleton, Snackbar, Alert } from '@mui/material';
import { updateUserAttribute } from 'aws-amplify/auth';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import ProfileTopStyles from "./accountDetails.module.css";
import { useUser } from '../../../../context/UserContext';
import { styled } from '@mui/material/styles';
import { TextField, InputAdornment } from '@mui/material';
import { BiHide, BiShow } from 'react-icons/bi';
import { IoMdEye } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { post } from 'aws-amplify/api';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            outline: 'none',
            border: '1.7px solid #bbbbbb',
            padding: '0.8rem',
            borderRadius: '24px',
            margin: '0.2rem 0',
        },
        "& input::placeholder": {
            fontSize: '13px',
        },
    },
});


export default function AccountDetails() {
    const [edit, setEdit] = useState(false);
    const [userId, setUserId] = useState(localStorage.getItem("bookstorecurrentloginuser") || null);
    const [userDetails, setUserDetails] = useState({
        email: '',
        lastName: '',
        firstName: ''
    });
    const [formValues, setFormValues] = useState({ ...userDetails });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(true);
    const [resetPasswordloading, setResetPasswordloading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { setFullName } = useUser();

    const handleButtonClick = () => {
        setEdit(prevEdit => {
            if (prevEdit) {
                setFormValues(userDetails);
            }
            return !prevEdit;
        });
    };


    const [email, setEmail] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [step, setStep] = useState('INIT'); // Tracks the current step of the reset process
    const [message, setMessage] = useState('');


    const handleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    // Initiates the password reset process
    const handleResetPassword = async () => {
        try {
            setResetPasswordloading(true)
            const output = await resetPassword({ username: userDetails.email });
            const { nextStep } = output;

            switch (nextStep.resetPasswordStep) {
                case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                    const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                    setMessage(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`);
                    setStep('CONFIRM');
                    setResetPasswordloading(false)
                    break;
                case 'DONE':
                    setMessage('Password reset successful.');
                    break;
                default:
                    setMessage('Unexpected step.');
            }
        } catch (err) {
            setError(`Error: ${err.message}`);
        }
    };

    // Confirms the new password with the provided code
    const handleConfirmResetPassword = async () => {
        setResetPasswordloading(true)
        try {
            await confirmResetPassword({ username: userDetails.email, confirmationCode, newPassword });
            setMessage('Successfully reset password.');
            setStep('DONE');
            setResetPasswordloading(false)
        } catch (err) {
            setError(`Error: ${err.message}`);
            setResetPasswordloading(false)
        }
    };

    useEffect(() => {
        if (!userId) return;

        const fetchUser = async () => {
            setLoading(true);
            try {
                
                const response = await fetch(`${apiBaseUrl}/items/get-user`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId })
                });

                // const response = post({
                //     apiName: 'apiBooks321',
                //     path: '/items/get-user',
                //     options: {
                //       headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify({ userId })
                //     }
                //   });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'An error occurred');
                }

                const data = await response.json();
                setUserDetails(data);
                setFormValues(data);
            } catch (err) {
                setError(err.message);
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [userId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSaveChanges = async () => {
        setLoading(true);
        setError('');
        setSuccess('');
        try {
           
            const response = await fetch(`${apiBaseUrl}/items/edit-user`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId, ...formValues })
            });

            // const response = post({
            //     apiName: 'apiBooks321',
            //     path: '/items/edit-user',
            //     options: {
            //       headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({ userId, ...formValues })
            //     }
            //   });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'An error occurred');
            }

            // Update Cognito user attributes
            await handleUpdateUserAttribute('custom:firstName', formValues.firstName);
            await handleUpdateUserAttribute('custom:lastName', formValues.lastName);

            setUserDetails(formValues);
            setEdit(false);

            const firstName = formValues.firstName;
            const lastName = formValues.lastName;


            const fullName = `${firstName.trim()} ${lastName}`;
            localStorage.setItem("CurrentFullName", fullName);
            setFullName(fullName);


            setSuccess('User updated successfully');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    const handleUpdateUserAttribute = async (attributeKey, value) => {
        try {
            const output = await updateUserAttribute({ userAttribute: { attributeKey, value } });
            handleUpdateUserAttributeNextSteps(output);
        } catch (error) {
            console.log('Error updating user attribute:', error);
            setError('Failed to update attribute in Cognito');
        }
    };

    const handleUpdateUserAttributeNextSteps = (output) => {
        const { nextStep } = output;

        switch (nextStep.updateAttributeStep) {
            case 'CONFIRM_ATTRIBUTE_WITH_CODE':
                const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                console.log(`Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`);
                // Collect the confirmation code from the user and pass to confirmUserAttribute.
                break;
            case 'DONE':
                console.log('Attribute was successfully updated.');
                break;
            default:
                console.log('Unhandled next step:', nextStep);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const capitalizeFirstLetter = (string) => string ? string.charAt(0).toUpperCase() + string.slice(1) : '';

    return (
        <div className={ProfileTopStyles.accountDetailsContainer}>
            <div className={ProfileTopStyles.profileTopRight}>
                <div className={ProfileTopStyles.detailsTop}>
                    <h1 className={ProfileTopStyles.rightTitle}>About you</h1>
                    <div className={ProfileTopStyles.leftBtnsContainer}>
                        <button
                            className={ProfileTopStyles.leftBtns}
                            style={{ padding: "5px 0" }}
                            onClick={handleButtonClick}
                        >
                            {edit ? "Cancel" : "Edit Details"}
                        </button>
                    </div>
                </div>
                {error && <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="error">{error}</Alert>
                </Snackbar>}
                {success && <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="success">{success}</Alert>
                </Snackbar>}
                {loading ? (
                    <div className={ProfileTopStyles.profileRightContent}>
                        <div className={ProfileTopStyles.detailsColLeft}>
                            <Skeleton variant="text" sx={{ width: { xs: '100%', sm: 100, md: 150, lg: 200 }, height: 25 }} />
                            <Skeleton variant="text" width={200} height={25} />
                            <Skeleton variant="text" width={200} height={25} />
                        </div>
                    </div>
                ) : (
                    <div className={ProfileTopStyles.profileRightContent}>
                        <div className={ProfileTopStyles.detailsColLeft}>
                            <span className={ProfileTopStyles.label}>First name:</span>
                            <span className={ProfileTopStyles.label}>Last name:</span>
                            <span className={ProfileTopStyles.label}>Email address:</span>
                        </div>
                        <div className={ProfileTopStyles.detailsColRight}>
                            {edit ? (
                                <div className={ProfileTopStyles.detailsColRightInputs}>
                                    <input
                                        type="text"
                                        name="firstName"
                                        className={ProfileTopStyles.customInput}
                                        value={formValues.firstName}
                                        onChange={handleInputChange}
                                        disabled={loading}
                                    />
                                    <input
                                        type="text"
                                        name="lastName"
                                        className={ProfileTopStyles.customInput}
                                        value={formValues.lastName}
                                        onChange={handleInputChange}
                                        disabled={loading}
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        disabled
                                        className={ProfileTopStyles.disabledInput}
                                        value={formValues.email}
                                    />
                                </div>
                            ) : (
                                <div className={ProfileTopStyles.detailsColRight}>
                                    <span className={ProfileTopStyles.value}>{capitalizeFirstLetter(userDetails.firstName)}</span>
                                    <span className={ProfileTopStyles.value}>{capitalizeFirstLetter(userDetails.lastName)}</span>
                                    <span className={ProfileTopStyles.value}>{capitalizeFirstLetter(userDetails.email)}</span>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {edit && (
                    <div className={ProfileTopStyles.leftBtnsContainerSaveChanges}>
                        <button
                            className={ProfileTopStyles.leftBtns}
                            style={{ padding: "5px 0" }}
                            onClick={handleSaveChanges}
                            disabled={loading}
                        >
                            Save Changes
                        </button>
                    </div>
                )}
            </div>
            {/* <div className={ProfileTopStyles.profileTopRightBottom}>
                <h1 className={ProfileTopStyles.rightTitleBottom}>Reset Password</h1>
                <p className={ProfileTopStyles.rightParaBottom}>
                    To reset your password, click below. You will receive an
                    e-mail allowing you to add a new password.
                </p>
                <div className={ProfileTopStyles.leftBtnsContainer} style={{ width: "200px" }}>
                    <button className={ProfileTopStyles.leftBtns} style={{ padding: "5px 0" }}>Reset Password</button>
                </div>
            </div> */}


            <div className={ProfileTopStyles.profileTopRightBottom}>
                <h1 className={ProfileTopStyles.rightTitleBottom}>Reset Password</h1>

                {step === 'INIT' && (
                    <>
                        <p style={{ fontSize: "15px" }} className={ProfileTopStyles.rightParaBottom}>
                            To reset your password, click below. You will receive an
                            e-mail allowing you to add a new password.
                        </p>

                        <div>
                            {/* <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={ProfileTopStyles.inputField}
                        /> */}

                            <div className={ProfileTopStyles.leftBtnsContainer} style={{ width: "200px" }}>
                                <button onClick={handleResetPassword} className={ProfileTopStyles.leftBtns} style={{ padding: "5px 0" }}>
                                    {resetPasswordloading ? <div className={ProfileTopStyles.loader}></div> : 'Reset'}
                                </button>
                            </div>



                            {/* <button
                            className={ProfileTopStyles.leftBtns}
                            onClick={handleResetPassword}
                        >
                            Send Reset Code
                        </button> */}
                        </div>
                    </>

                )}

                {step === 'CONFIRM' && (
                    // {step === 'INIT' && (
                    <>
                        <p style={{
                            fontWeight: 600,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '15px'
                        }} className={ProfileTopStyles.rightParaBottom}>
                            {message === "Confirmation code was sent to EMAIL" && (
                                <span style={{
                                    background: '#bbcd52',
                                    color: 'white',
                                    padding: '4px',
                                    width: '29px',
                                    height: '29px',
                                    borderRadius: '22px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '10px'
                                }}>
                                    <FaCheck />
                                </span>
                            )}

                            {message}
                        </p>
                        <div className={ProfileTopStyles.confirmPassword}>

                            <CssTextField
                                variant="outlined"
                                fullWidth
                                value={confirmationCode}
                                type="text"
                                placeholder="Enter confirmation code"
                                onChange={(e) => setConfirmationCode(e.target.value)}
                                required
                            // InputProps={{
                            //     startAdornment: (
                            //         <InputAdornment position="start">
                            //             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            //                 <path fillRule="evenodd" clipRule="evenodd" d="M4.99984 3.33331H14.9998C16.8408 3.33331 18.3332 4.8257 18.3332 6.66665V14.1666C18.3332 16.0076 16.8408 17.5 14.9998 17.5H4.99984C3.15889 17.5 1.6665 16.0076 1.6665 14.1666V6.66665C1.6665 4.8257 3.15889 3.33331 4.99984 3.33331ZM11.1665 11.2916L16.4498 7.37498C16.6856 7.20164 16.7377 6.87067 16.5665 6.63331C16.4849 6.51999 16.3609 6.44452 16.2227 6.42411C16.0846 6.40371 15.9441 6.44009 15.8332 6.52498L10.4915 10.4166C10.3578 10.5567 10.1726 10.636 9.979 10.636C9.78537 10.636 9.60017 10.5567 9.4665 10.4166L4.1665 6.52498C4.05615 6.44276 3.91756 6.40797 3.78146 6.4283C3.64536 6.44864 3.523 6.52243 3.4415 6.63331C3.26852 6.86851 3.31681 7.19908 3.54984 7.37498L8.79984 11.25C9.12652 11.5599 9.55792 11.7354 10.0082 11.7416C10.4369 11.7416 10.8501 11.581 11.1665 11.2916Z" fill="#70a3a9" />
                            //             </svg>
                            //         </InputAdornment>
                            //     ),
                            // }}
                            />



                            <CssTextField
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter new password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                                name="password"

                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                // error={loginError}
                                // helperText={loginError && loginError}

                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z" fill="#70a3a9" />
                                            </svg>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <div className={ProfileTopStyles['iconDivLock']} >
                                                {showPassword ? (
                                                    <BiHide onClick={handleShowPassword} className={ProfileTopStyles['eye-icon']} />
                                                ) : (
                                                    <IoMdEye onClick={handleShowPassword} className={ProfileTopStyles['eye-icon']} />
                                                )}
                                            </div>
                                        </InputAdornment>
                                    ),
                                }}
                            />




                            {error && <p className={ProfileTopStyles.error}>{error}</p>}
                            <div className={ProfileTopStyles.leftBtnsContainer} style={{ width: "100%" }}>
                                <button onClick={handleConfirmResetPassword} className={ProfileTopStyles.leftBtns} style={{ padding: "5px 0" }}>
                                    {resetPasswordloading ? <div className={ProfileTopStyles.loader}></div> : ' Confirm New Password'}
                                </button>
                            </div>

                        </div>
                    </>
                )}

                {step === 'DONE' && (
                    // {step === 'INIT' && (
                    <>
                        <p style={{
                            fontWeight: 600,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '15px'
                        }} className={ProfileTopStyles.rightParaBottom}>
                            {message === "Successfully reset password." && (
                                <span style={{
                                    background: '#bbcd52',
                                    color: 'white',
                                    padding: '4px',
                                    width: '29px',
                                    height: '29px',
                                    borderRadius: '22px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '10px'
                                }}>
                                    <FaCheck />
                                </span>
                            )}

                            {message}
                        </p>

                    </>
                )}

                {/* {message && <p className={ProfileTopStyles.message}>{message}</p>} */}

            </div>
        </div>
    );
}
