import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import styles from "./UpdateVoucher.module.css";
import MessagePopup from "./Messagepopup";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const UpdateVoucher = ({
  selectedVoucher,
  onVoucherAdded,
  setSelectedVoucher,
  popupData,
  setPopupData
}) => {
  const [loading, setLoading] = useState(false);

 

  const [voucherData, setVoucherData] = useState({
    PK: selectedVoucher.PK,
    voucherName:'',
    Discount: '',
    StartDate: '',
    EndDate: '',
    isActive: false,
    discountType: '',
    Limit: 0
  });

  useEffect(() => {
    if (selectedVoucher) {
      setVoucherData(selectedVoucher);
    }
  }, [selectedVoucher]);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setVoucherData({ ...voucherData, [name]: value });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Handle Limit changes specifically
    // const parsedValue = name === 'Limit' ? Math.max(0, parseInt(value, 10) || 0) : value;

    setVoucherData({ ...voucherData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      
      const res = await fetch(`${apiBaseUrl}/items/admin-update-voucher`, {
        method: 'PUT',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            PK: selectedVoucher.PK,
            voucherName: selectedVoucher.voucherName,
            Discount: Number(voucherData.Discount),
            StartDate: voucherData.StartDate,
            EndDate: voucherData.EndDate,
            isActive: voucherData.isActive,
            discountType: voucherData.discountType,
            Limit: Number(voucherData.Limit)
        }),
      });

      // console.log("data",JSON.stringify(voucherData));
      // console.log("res", res);
      const data = await res.json();
    // console.log("Server response:", data); 

      if (res.ok) {
        setPopupData({ message: "Voucher updated successfully!", status: "success", show: true });
        onVoucherAdded();
        // alert("Voucher saved successfully");
        setSelectedVoucher(null)
      } else {
        setPopupData({ message: "Failed to update voucher!", status: "failure", show: true });
      }
    } catch (error) {
  console.error("Error saving voucher:", error);
      setPopupData({ message: "An error occurred while updating the voucher!", status: "failure", show: true });
    }
    setLoading(false);
  };

  
  return (
    <div>
    <form className={styles.voucherFormContainer} onSubmit={handleSubmit}>
      <div className={styles.voucherFormBox}>
        <div className={styles.insamelinetitleandclose}>
            <h2 className={styles.formTitle}>Edit Voucher</h2>
            <div className={styles.closeicon} onClick={()=> setSelectedVoucher(null)}><span>&times;</span></div>
        </div>
        <div className={styles.insameline}>
        <div className={styles.cloumnValue}>
        <span className={styles.inputValue}>Voucher Code</span>
          <input
            name="PK"
            value={voucherData.PK}
            onChange={handleChange}
            placeholder="Voucher Code"
            required
            disabled
          />
          </div>
          <div className={styles.cloumnValue}>
        <span className={styles.inputValue}>Voucher Name</span>
          <input
            name="voucherName"
            value={voucherData.voucherName}
            onChange={handleChange}
            placeholder="Voucher Name"
            required
            disabled
          />
          </div>
        </div>
        <div className={styles.insameline}>
        <div className={styles.cloumnValue}>
        <span className={styles.inputValue}>Discount (%)</span>
          <input
            name="Discount"
            value={voucherData.Discount}
            onChange={handleChange}
            placeholder="Discount (%)"
            type="number"
            step="0.1"
            max={100}
            min={0}
            required
          />
          </div>
          <div className={styles.cloumnValue}>
        <span className={styles.inputValue}>Usage Limit</span>
          <input
            name="Limit"
            value={voucherData.Limit}
            onChange={handleChange}
            placeholder="Limit"
            type="number"
            min={0}
            required
          />
          </div>
        </div>
        <div className={styles.insameline}>
        <div className={styles.cloumnValue}>
        <span className={styles.inputValue}>Start Date</span>
          <input
            name="StartDate"
            value={voucherData.StartDate}
            onChange={handleChange}
            placeholder="Start Date"
            type="date"
            required
          />
          </div>
          <div className={styles.cloumnValue}>
        <span className={styles.inputValue}>End Date</span>
          <input
            name="EndDate"
            value={voucherData.EndDate}
            onChange={handleChange}
            placeholder="End Date"
            type="date"
            required
          />
          </div>
        </div>

        <div className={styles.cloumnValue}>
          <span className={styles.inputValue}>Discount Type</span>
          <select
            style={{fontSize: '12px'}}
            name="discountType"
            value={voucherData.discountType} // Set default value as 'Total'
            onChange={handleChange}
            className={styles.dropdown} // Add styling here if needed
            required
          >
            <option value="Total">Total (Items Price + Postage and Packaging Cost)</option>
            <option value="OnlyItems">Items Price Only</option>
          </select>
        </div>
        {/* abc */}

        <div className={styles.isActivelabel}>
        <div className={styles.cloumnValue}>
        <span className={styles.inputValue}>Voucher Status</span>
        <div className={styles.isActivelabel}>
          <input
            name="isActive"
            type="checkbox"
            checked={voucherData.isActive}
            onChange={() =>
              setVoucherData({
                ...voucherData,
                isActive: !voucherData.isActive,
              })
            }
          />
          <span className={styles.isActivetitle}>
            {voucherData.isActive ? "Active" : "not-active"}
          </span>
          </div>
          </div>
        </div>

        <button className={styles.submitButton} type="submit">
        Update Voucher
      </button>

      </div>


      
      {loading && (
        <div className={styles.loader}>
          <Oval
            visible={true}
            height="80"
            width="80"
            color="#fff"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
    </form>
    {/* Conditionally render the popup
    {popupData.show && (
        <MessagePopup
          message={popupData.message}
          status={popupData.status}
          onClose={handlePopupClose}
        />
      )} */}
    </div>
  );
};

export default UpdateVoucher;
