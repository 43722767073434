import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamationCircle, FaInfoCircle } from 'react-icons/fa'; // Icons for different states
import styles from './MessagePopupcomponent.module.css'; // Assuming you're using CSS modules

const MessagePopup = ({ message, status, onClose }) => {
  const [popupEffect, setPopupEffect] = useState(true); // Track popup open/close animation

  useEffect(() => {
    // Automatically close popup after 3 seconds
    const timer = setTimeout(() => {
      handleClosePopup();
    }, 3000);

    // Cleanup timer
    return () => clearTimeout(timer);
  }, []);

  const handleClosePopup = () => {
    // Trigger closing animation
    setPopupEffect(false);
    // Delay the actual close to allow the animation to finish
    setTimeout(() => {
      onClose();
    }, 300); // Match the duration of the close animation
  };

  // Determine the icon and color based on status
  const getIconAndStyle = () => {
    switch (status) {
      case 'success':
        return { icon: <FaCheckCircle />, color: 'green' };
      case 'updated':
        return { icon: <FaInfoCircle />, color: 'blue' };
      case 'failure':
        return { icon: <FaExclamationCircle />, color: 'red' };
      default:
        return { icon: <FaInfoCircle />, color: 'gray' };
    }
  };

  const { icon, color } = getIconAndStyle();

  return (
    <div
      className={`${styles.popupContainer} ${
        popupEffect ? styles.popupOpen : styles.popupClose
      }`}
      style={{ borderColor: color }}
    >
      <div className={styles.iconContainer} style={{ color }}>
        {icon}
      </div>
      <div className={styles.messageContent}>
        <span>{message}</span>
      </div>
      <button className={styles.closeButton} onClick={handleClosePopup}>
        &times;
      </button>
    </div>
  );
};

export default MessagePopup;
