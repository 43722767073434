import { createSlice } from "@reduxjs/toolkit";

const characterSlice = createSlice({
    name: 'characterinfo',
    initialState: {
        gender:"Boy",
        book:"",
        bookData: [],
        eyeColor:"Browneye",
        skincolor:"lightskin",
        hairstyle:"short",
        haircolor:"Brownhair",
        town:"",
        childname:"",
        teacher1 : "",
        teacher2: "",
        teacher3: "",
        teacher4: ""
    },
    
    reducers: {
        setgender(state, action) {
            state.gender = action.payload;
        },

        setbook(state, action) {
            state.book = action.payload;
        },

        seteyeColor(state, action) {
            state.eyeColor = action.payload;
        },

        setskincolor(state, action) {
            state.skincolor = action.payload;
        },

        sethairstyle(state, action) {
            state.hairstyle = action.payload;
        },

        sethaircolor(state, action) {
            state.haircolor = action.payload;
        },

        settown(state, action) {
            state.town = action.payload;
        },

        setchildname(state, action) {
            state.childname = action.payload;
        },
        setBookdata(state, action) {
            state.bookData = action.payload;
        },


        setTeacher1(state, action) {
            state.teacher1 = action.payload;
        },
        setTeacher2(state, action) {
            state.teacher2 = action.payload;
        },
        setTeacher3(state, action) {
            state.teacher3 = action.payload;
        },
        setTeacher4(state, action) {
            state.teacher4 = action.payload;
        }
       
    },
})

export const {setTeacher1, setTeacher2, setTeacher3, setTeacher4, gender, book, eyeColor, skincolor, hairstyle, haircolor, town, childname, setgender, setbook ,seteyeColor, setskincolor, sethairstyle, sethaircolor, settown, setchildname, setBookdata} = characterSlice.actions;
export default characterSlice.reducer;