import React from 'react'
import ReviewsHomeComponentstyles from './ReviewsHomeComponent.module.css'
import reviewcharacter1 from '../../../../Assets/Images/Home/reviewscharacter1.webp'
import reviewcharacter2 from '../../../../Assets/Images/Home/reviewscharacter2.webp'
// import user from '../../../../Assets/Images/Home/user.jpg'
// import usericon from '../../../../Assets/Images/SigninSignup/user_pic.webp'
import { FaCircleUser } from "react-icons/fa6";

const ReviewsHomeComponent = () => {
  return (
    <div className={ReviewsHomeComponentstyles.main}>
        <h1 className={ReviewsHomeComponentstyles.mainheading}>See What Everyone <br/> Has To Say</h1>
        <div className={ReviewsHomeComponentstyles.rowContainer}>
            <img src={reviewcharacter1} alt='reviewcharacter1' className={ReviewsHomeComponentstyles.image}/>
                <div className={ReviewsHomeComponentstyles.reviews}>

                <div className={ReviewsHomeComponentstyles.reviewCard}>
                    <div className={ReviewsHomeComponentstyles.reviewCardContent}>
                        <div className={ReviewsHomeComponentstyles.usericonContainer}>
                            {/* <img src={usericon} alt='usericon' className={ReviewsHomeComponentstyles.usericon}/> */}
                            <FaCircleUser className={ReviewsHomeComponentstyles.userIconitem}/>
                        </div>
                        <span style={{color:'#F1C8DA', fontWeight:'bold'}}>Katie, Neasden</span>
                        <p className={ReviewsHomeComponentstyles.reviewParagraph}>
                            These books are like nothing I’ve seen before – bought 2 for my children and they ask for the story pretty much every night now. Thank You!  
                        </p>
                    </div>
                    <div className={ReviewsHomeComponentstyles.frame}>
                        <svg width="240" height="160" viewBox="0 0 245 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M220.219 24.587L28.5664 33.2549L0.0376282 4.72616V97.1908C0.0376282 108.258 9.01515 117.235 20.0823 117.235H224.659C235.727 117.235 244.704 108.258 244.704 97.1908V0.0922852L220.219 24.587Z" fill="#F8CDE0"/>
                        </svg>
                    </div>
                </div>

                <div className={ReviewsHomeComponentstyles.reviewCard}>
                    <div className={ReviewsHomeComponentstyles.reviewCardContent}>
                    <div className={ReviewsHomeComponentstyles.usericonContainer}>
                            {/* <img src={usericon} alt='usericon' className={ReviewsHomeComponentstyles.usericon}/> */}
                            <FaCircleUser className={ReviewsHomeComponentstyles.userIconitem}/>
                        </div>  
                        <span style={{color:'#B3D1E5', fontWeight:'bold'}}>Joe S, Harrogate</span>
                        <p className={ReviewsHomeComponentstyles.reviewParagraph}>
                            Look amazing. The story is good too and promotes good behaviour such as doing homework. Have recommended to all my friends with young children
                        </p>                  
                    </div>
                    <div className={ReviewsHomeComponentstyles.frame1}>
                        <svg width="240" height="160" viewBox="0 0 246 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.2375 89.1973H216.89L245.409 117.716V21.0339C245.409 9.96678 236.432 0.989258 225.365 0.989258H20.7874C9.72032 0.989258 0.742798 9.96678 0.742798 21.0339V113.692L25.2375 89.1973Z" fill="#B7D6EA"/>
                    </svg>
                    </div>
                </div>

                <div className={ReviewsHomeComponentstyles.reviewCard}>
                    <div className={ReviewsHomeComponentstyles.reviewCardContent}>
                    <div className={ReviewsHomeComponentstyles.usericonContainer}>
                            {/* <img src={usericon} alt='usericon' className={ReviewsHomeComponentstyles.usericon}/> */}
                            <FaCircleUser className={ReviewsHomeComponentstyles.userIconitem}/>
                        </div>
                        <span style={{color:'#C29768', fontWeight:'bold'}}>Iwona, Barking</span>  
                        <p className={ReviewsHomeComponentstyles.reviewParagraph}>
                            Really lovely book, full of positivity and I really like how it encourages children to follow their dreams. My 2 daughters really enjoyed listening to this story and looking at the illustrations.
                        </p>
                                        </div>
                    <div className={ReviewsHomeComponentstyles.frame}>
                    <svg width="240" height="160" viewBox="0 0 246 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M220.688 24.587L29.0348 33.2549L0.515686 4.73583V97.2004C0.515686 108.268 9.49324 117.245 20.5604 117.245H225.138C236.205 117.245 245.182 108.268 245.182 97.2004V0.0922852L220.688 24.587Z" fill="#C79A68"/>
                </svg>
                    </div>
                </div>

                <div className={ReviewsHomeComponentstyles.reviewCard}>
                    <div className={ReviewsHomeComponentstyles.reviewCardContent}>
                    <div className={ReviewsHomeComponentstyles.usericonContainer}>
                            {/* <img src={usericon} alt='usericon' className={ReviewsHomeComponentstyles.usericon}/> */}
                            <FaCircleUser className={ReviewsHomeComponentstyles.userIconitem}/>
                        </div>
                        <span style={{color:'#8087AA', fontWeight:'bold'}}>Romit, Milton Keynes</span>   
                        <p className={ReviewsHomeComponentstyles.reviewParagraph}>
                            My young son who loves football was so happy with the footballer book, now most of his teammates at soccer school have got them now. Great read and amazing pictures.
                        </p>
                                        </div>
                    <div className={ReviewsHomeComponentstyles.frame1}>
                    <svg width="240" height="160" viewBox="0 0 245 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.7059 89.1973H216.359L244.878 117.716V21.0339C244.878 9.96678 235.9 0.989258 224.833 0.989258H20.2654C9.19832 0.989258 0.220825 9.96678 0.220825 21.0339V113.692L24.7059 89.1973Z" fill="#8087AA"/>
                </svg>
                    </div>
                </div>
   
                </div>
            <img src={reviewcharacter2} alt='reviewcharacter2' className={ReviewsHomeComponentstyles.image}/>
        </div>
    </div>
  )
}

export default ReviewsHomeComponent