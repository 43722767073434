// Modal.js
import React from 'react';
import styles from './CustomModal.module.css';

const ConfirmModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modal} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeader}>Confirm Information</div>
        <div className={styles.modalBody}>
          Are you sure the information entered is correct?
        </div>
        <div className={styles.modalFooter}>
          <button className={`${styles.modalButton} ${styles.cancelButton}`} onClick={onClose}>
            Cancel
          </button>
          <button className={`${styles.modalButton} ${styles.confirmButton}`} onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
