import ProfileTopStyles from "./orderHistory.module.css"
import starIcon from "../../../../Assets/SVG/startSvg.svg"
import filledStar from "../../../../Assets/SVG/starFilled.svg"
import '../../../../fonts.css';
import React, { useState, useEffect } from 'react';
import { get } from 'aws-amplify/api';

// const orders = [
//     { imgUrl: book1Image, title: "I Want To Be An Astronaut", rating: 5, orderDate: "March 23, 2024", price: "23.99" },
//     { imgUrl: book1Image, title: "I Want To Be An Astronaut", rating: 5, orderDate: "March 23, 2024", price: "23.99" },
//     { imgUrl: book1Image, title: "I Want To Be An Astronaut", rating: 5, orderDate: "March 23, 2024", price: "23.99" },
//     { imgUrl: book1Image, title: "I Want To Be An Astronaut", rating: 5, orderDate: "March 23, 2024", price: "23.99" },
//     { imgUrl: book1Image, title: "I Want To Be An Astronaut", rating: 5, orderDate: "March 23, 2024", price: "23.99" },
// ]

const stars = [starIcon, starIcon, starIcon, starIcon, starIcon,]

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export default function OrderHistory() {
    const [customerId, setCustomerId] = useState(localStorage.getItem("bookstorecurrentloginuser") || null);
    const [orders, setOrders] = useState([]);
    const [isOrder, setIsOrder] = useState(true);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFetchOrders = async () => {
        if (!customerId) {
            setError('Please provide a customer ID.');
            return;
        }
    
        setLoading(true);
        
        const fetchOrders = async () => {
            const url = `${apiBaseUrl}/items/get-orders-for-customer?customerId=${encodeURIComponent(customerId)}`;
            const response = await fetch(url);
    
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            return response.json();
        };
    
        const extractData = (data) => {
            if (!Array.isArray(data)) {
                throw new Error('Invalid data format');
            }
    
            return data.flatMap(order =>
                order.item.map(item => ({
                    orderDate: order.orderDate,
                    totalPrice: order.totalPrice,
                    rating: item.rating,
                    quantity: item.quantity,
                    title: item.title,
                    price: item.price,
                    bookId: item.bookId,
                    imageUrl: item.imageUrl
                }))
            );
        };
    
        try {
            const data = await fetchOrders();
            const extractedData = extractData(data);
            setOrders(extractedData);
            setError(null);
        } catch (err) {
            console.error('Error fetching customer orders:', err);
            setError('Unable to fetch orders. Please try again later.');
            setOrders([]);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if (customerId) {
            handleFetchOrders(customerId);
        }
    }, [customerId]);

    function formatOrderDate(orderDate) {
        // Create a new Date object from the input string
        const date = new Date(orderDate);
        // Define options for formatting the date
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        // Format the date
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    }

    function extractImageUrl(urlString) {
        if (urlString) {
            const regex = /url\((['"]?)(.*?)\1\)/;
            const match = urlString.match(regex);
            return match ? match[2] : urlString;
        }
        return null;
    }

    function formatPrice(item) {
        const quantity = parseFloat(item.quantity);
        const price = parseFloat(item.price);
        const totalPrice = quantity * price;

        return quantity > 1
            ? `£${totalPrice.toFixed(2)}`
            : `£${price.toFixed(2)}`;
    }

    return (
        <div className={ProfileTopStyles.profileTopRight}>
            <h1 className={ProfileTopStyles.rightTitle}>ORDER HISTORY</h1>
            {loading ? (
                <div className={ProfileTopStyles.loaderDiv} >

                <div className={ProfileTopStyles.loader}></div>
                </div>
            ) : (
                (orders && orders.length !== 0) ? (
                    <div className={ProfileTopStyles.profileRightContent}>
                        {
                            orders.map((item, index) => {
                                return <div key={index} className={ProfileTopStyles.orderItem}>
                                    <div ProfileTopStyles={ProfileTopStyles.imgContainer}>

                                        <img src={extractImageUrl(item.imageUrl)} alt="" className={ProfileTopStyles.itemImg} />
                                    </div>
                                    <div className={ProfileTopStyles.itemDetailContainer}>
                                        <div className={ProfileTopStyles.itemTop}>
                                            <p className={ProfileTopStyles.itemTitle}>{item.title}</p>
                                            <div className={ProfileTopStyles.starsContainer}>
                                                {Array.from({ length: item.rating }, (_, index) => (
                                                    <img key={index} src={filledStar} alt="Star" style={{ color: "yellow" }} />
                                                ))}

                                                {
                                                    stars.slice(item.rating, 5).map((star, index) => {
                                                        return <img key={index} src={star} alt="Star" style={{ color: "yellow" }} />
                                                    })
                                                }

                                            </div>
                                        </div>
                                        <div className={ProfileTopStyles.itemBottom}>
                                            <span className={ProfileTopStyles.date}>
                                                Order Date: {formatOrderDate(item.orderDate)}
                                            </span>
                                            <span className={ProfileTopStyles.priceContainer}>
                                                {item.quantity} {parseFloat(item.quantity) > 1 ? 'Books' : 'Book'}
                                                <span className={ProfileTopStyles.price}>
                                                    {formatPrice(item)}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                ) : (
                    <div className={ProfileTopStyles.profileRightContent}>
                        <h4 className={ProfileTopStyles.rightSubTitle}>You have no iwant2bea orders</h4>
                        {/* <p className={ProfileTopStyles.rightPara}>
                            Start personalizing one of the 100 titles in our inventory
                            We have it all, from bedtime stories, to nursery rhymes
                            to superhero adventures.
                        </p>
                        <div className={ProfileTopStyles.leftBtnsContainer} style={{ width: "200px" }}>
                            <button className={ProfileTopStyles.leftBtns} style={{ padding: "5px 0" }}>Our Books</button>
                        </div> */}
                    </div>
                )
            )}
        </div>
    )
}