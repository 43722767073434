import starStyles from "./star.module.css"

export default function Stars({ width = "100", height = "36" }) {
    return (
        <div className={starStyles['svg-container']}>
            <svg
                style={{ height: "auto" }}
                viewBox="0 0 231 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M163.9 28.2897L175.457 35.2L172.39 22.176L182.6 13.413L169.155 12.2829L163.9 0L158.645 12.2829L145.2 13.413L155.41 22.176L152.344 35.2L163.9 28.2897Z"
                    fill="#FFB618"
                />
                <path
                    d="M115.5 28.2897L127.056 35.2L123.99 22.176L134.2 13.413L120.754 12.2829L115.5 0L110.245 12.2829L96.7998 13.413L107.01 22.176L103.943 35.2L115.5 28.2897Z"
                    fill="#FFB618"
                />
                <path
                    d="M67.0999 28.2897L78.6565 35.2L75.5897 22.176L85.7999 13.413L72.3546 12.2829L67.0999 0L61.8452 12.2829L48.3999 13.413L58.6101 22.176L55.5433 35.2L67.0999 28.2897Z"
                    fill="#FFB618"
                />
                <path
                    d="M18.7 28.2897L30.2566 35.2L27.1898 22.176L37.4 13.413L23.9547 12.2829L18.7 0L13.4453 12.2829L0 13.413L10.2102 22.176L7.1434 35.2L18.7 28.2897Z"
                    fill="#FFB618"
                />
                <path
                    d="M212.3 28.2897L223.856 35.2L220.79 22.176L231 13.413L217.555 12.2829L212.3 0L207.045 12.2829L193.6 13.413L203.81 22.176L200.743 35.2L212.3 28.2897Z"
                    fill="#FFB618"
                />
            </svg>
        </div>
    )
}
